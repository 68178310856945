.intro-section {
  background-color: $yalloo-primary-500;
  padding: 18rem 12rem 0rem 12rem;
  @include flex-center(column);
  @media (max-width: 700px) {
    padding: 30rem 7rem 0rem 7rem;
    gap: 3rem;
  }
  align-items: flex-start;
  .intro-top-wrapper {
    font-size: 23.4rem;
    font-family: $marco-medium;
    font-weight: 500;
    line-height: 0.8;
    @media (max-width: 700px) {
      font-size: 21.4rem;
    }

    .intro-top-text {
      .hidden {
        overflow: hidden;
        position: relative;
        z-index: 111;
        .purpal-cover {
          position: relative;
          display: inline-flex;
          justify-content: center;
        }

        .slide-intro {
          line-height: 0.8;
          word-break: keep-all;
          display: inline-block;
          padding: 0 0rem 5rem 0.8rem;
          transform: translateY(110%);
        }

        svg {
          position: absolute;
          bottom: -15px;
          width: 100%;
          height: 100%;
          transform: scale(2) scaleX(0.6);
          z-index: 11;
          @media (max-width: 700px) {
            bottom: -6px;
          }
        }
      }
      .hidden:nth-child(1) {
        z-index: 11;
      }
      .hidden:nth-child(2) {
        transform: translateY(-6rem);
      }
      .hidden:nth-child(3) {
        transform: translateY(-12rem);
      }
      .purpal-non {
        color: $purpal-secondary-500;
        align-self: center;
      }
    }
  }

  .intro-bottom-wrapper {
    @include flex-center(row);
    margin-bottom: 16.8rem;
    padding: 0 3rem;
    overflow: hidden;
    @media (max-width: 700px) {
      @include flex-center(column);
      gap: 10rem;
      padding: 0 0rem;
    }
    justify-content: space-between;
    width: 100%;

    .first-block-container {
      transform: translateY(100%);
      font: 500 6rem $marco-medium;
      width: 63rem;
      align-self: flex-end;
      letter-spacing: 0.4rem;
      padding-left: 1rem;

      @media (max-width: 700px) {
        align-self: flex-start;
        width: 100%;
        font-size: 10rem;
      }
      .word-container {
        position: relative;
        display: inline-block;
        vertical-align: top;
      }
      .b-word-container {
        white-space: nowrap;
        vertical-align: baseline;

        .b-word {
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          padding-left: 0.3rem;
        }
      }
      .a-word-container {
        vertical-align: baseline;
        transform: translateY(100%);
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 1rem;
        .a-word {
          display: inline-block;
          color: $purpal-secondary-500;
          transform: translateY(-100%);
          padding-bottom: 0.4rem;
          padding-left: 0.3rem;
        }
      }

      p {
        line-height: 0.9;
      }
    }

    .second-block-container {
      font: 500 2.4rem $text-regular;
      align-self: flex-end;
      width: 65rem;
      text-align: right;
      padding-left: 1rem;
      line-height: 1.2;
      padding-bottom: 0.1rem;
      transform: translateY(100%);
      @media (max-width: 700px) {
        align-self: flex-start;
        width: 100%;
        font-size: 5.5rem;
        text-align: left;
      }
    }
  }
}
