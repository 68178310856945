@font-face {
  font-family: "marco-bold";
  src: url("../assets/fonts/milligram/milligram_macrobold_macroman/milligrammacro-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: "marco-medium";
  src: url("../assets/fonts/milligram/milligram_macromedium_macroman/milligrammacro-medium-webfont.woff") format("woff");
}
@font-face {
  font-family: "marco-light";
  src: url("../assets/fonts/milligram/milligram_light_macroman/milligram-light-webfont.woff") format("woff");
}
@font-face {
  font-family: "text-bold";
  src: url("../assets/fonts/milligram/milligram_textbold_macroman/milligramtext-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: "text-regular";
  src: url("../assets/fonts/milligram/milligram_textregular_macroman/milligramtext-regular-webfont.woff") format("woff");
}
@font-face {
  font-family: "text-medium";
  src: url("../assets/fonts/milligram/milligram_textmedium_macroman/milligramtext-medium-webfont.woff") format("woff");
}
@font-face {
  font-family: "milgram-medium";
  src: url("../assets/fonts/milligram/milligram_medium_macroman/milligram-medium-webfont.woff") format("woff");
}
.below {
  margin-left: auto;
}

.big {
  color: rgb(107, 34, 255);
  padding: 0 !important;
}

.black {
  color: black; /* or any other desired color */
  --my-variable: black; /* or any other desired variable */
  fill: black;
}

.white {
  color: white; /* or any other desired color */
  --my-variable: white; /* or any other desired variable */
  fill: white;
}

@media (max-width: 700px) {
  .full {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.blog_category-container .swiper-slide {
  width: auto !important; /* Ensure the width of the slide is determined by its content */
  flex-shrink: 0; /* Prevent the slide from shrinking */
}

strong {
  font-weight: bold !important;
}

.blog-btn {
  transform: translateY(-30%);
  padding: 0 !important;
}

.s {
  transform: scaleY(1) !important;
}

.l {
  transform: scaleY(0.76) !important;
}

.tl {
  margin-left: 1.2rem !important;
}

@media (max-width: 700px) {
  .ts {
    margin-left: 1 !important;
  }
}

.no-blog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  font-family: "text-medium";
}

.veild {
  background-color: #847f00;
}

.light {
  background-color: rgb(255, 246, 30);
}

.header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
}
.header__title {
  font-size: 6rem;
  font-family: "text-medium";
  font-weight: 600;
  letter-spacing: -2px;
  align-self: center;
  line-height: 1;
  cursor: pointer;
  padding: 0rem 0 0 4rem;
}
@media (max-width: 700px) {
  .header__title {
    font-size: 9rem;
  }
}
.header__title .letter-cel {
  display: inline-block;
  border-radius: 5px;
}
.header__title .letter-cel svg {
  width: 100%;
  height: 4rem;
}
.header__title .title-t {
  font-family: "marco-medium";
  letter-spacing: 1px;
}
.header__title .title-k {
  font-family: "marco-medium";
  letter-spacing: 1px;
}
.header__title .title-word {
  transform: translate(-27%);
  display: inline-block;
}
.header__nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 4rem 4rem 0 0;
}
.header__nav .soical-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 34rem;
  gap: 8rem;
  z-index: 1111;
}
.header__nav .soical-menu li {
  text-align: right !important;
}
@media (max-width: 700px) {
  .header__nav .soical-menu {
    align-self: center;
    margin-bottom: 2rem;
    margin-top: 10rem;
    justify-content: space-around;
    width: 100%;
  }
}
.header__nav .soical-menu .policy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  gap: 5px;
}
.header__nav .soical-menu .policy-container p {
  align-self: flex-end;
}
.header__nav .soical-menu .policy {
  font-size: 2.5rem;
  color: rgb(255, 246, 30);
  font-family: "text-regular";
  display: block;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  position: relative;
  padding-bottom: 0.4rem !important;
}
.header__nav .soical-menu .policy::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(255, 246, 30);
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  transform-origin: left;
}
.header__nav .soical-menu .policy:hover::after {
  transform: scaleX(1);
  transform: scaleY(0.8);
}
@media (max-width: 700px) {
  .header__nav .soical-menu .policy {
    font-size: 3rem;
  }
}
@media (max-width: 700px) {
  .header__nav .soical-menu .policy {
    font-size: 6rem;
    padding-bottom: 0.7rem !important;
  }
}
.header__nav .soical-menu .cookie {
  font-size: 2.5rem;
  color: rgb(255, 246, 30);
  font-family: "text-regular";
  display: block;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  position: relative;
  padding-bottom: 0.4rem !important;
}
.header__nav .soical-menu .cookie::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(255, 246, 30);
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  transform-origin: left;
}
.header__nav .soical-menu .cookie:hover::after {
  transform: scaleX(1);
  transform: scaleY(0.8);
}
@media (max-width: 700px) {
  .header__nav .soical-menu .cookie {
    font-size: 3rem;
  }
}
@media (max-width: 700px) {
  .header__nav .soical-menu .cookie {
    font-size: 6rem;
    padding-bottom: 0.7rem !important;
  }
}
.header__nav .soical-menu .copy-rights {
  font-size: 2.5rem;
  color: rgb(255, 246, 30);
  font-family: "text-regular";
  line-height: 1.3;
  display: block;
  width: -moz-max-content;
  width: max-content;
}
@media (max-width: 700px) {
  .header__nav .soical-menu .copy-rights {
    font-size: 3rem;
  }
}
@media (max-width: 700px) {
  .header__nav .soical-menu .copy-rights {
    font-size: 6rem;
  }
}
.header__nav .nav-menu {
  font-size: 3rem;
  position: relative;
  padding-top: 1rem;
  font-family: "text-medium";
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
}
@media (max-width: 700px) {
  .header__nav .nav-menu {
    margin-top: 3rem;
  }
}
.header__nav .nav-menu::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  top: 4.1rem;
  left: 0;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  transform-origin: left;
}
@media (max-width: 700px) {
  .header__nav .nav-menu::after {
    top: 6.5rem;
  }
}
.header__nav .nav-menu:hover::after {
  transform: scaleX(1);
  transform: scaleY(1.1);
  background-color: var(--my-variable);
}
@media (max-width: 700px) {
  .header__nav .nav-menu {
    font-size: 5.5rem;
  }
}
.header__nav .nav-colorful {
  margin: 0 !important;
}
.header__nav .menu-open-underline {
  align-self: flex-start;
  margin: 0;
  transform: translate(0, -3px);
  background: rgb(0, 0, 0);
}
.header__nav .list-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  height: 100vh;
  z-index: 1111;
  width: 100vw;
  padding: 4rem 12rem 4rem 12rem;
}
@media (max-width: 700px) {
  .header__nav .list-container {
    padding: 0rem 0rem 4rem 0rem;
  }
}
.header__nav .list-container .menu-scroll {
  overflow-y: scroll;
  height: 100vh;
}
.header__nav .list-container .menu-scroll::-webkit-scrollbar {
  display: none;
}
.header__nav .list-container .menu-lang {
  font: 500 2.5rem "text-medium";
  z-index: 111;
  position: relative;
  color: white;
  top: 18vh;
  left: 0.6rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
}
@media (max-width: 700px) {
  .header__nav .list-container .menu-lang {
    font-size: 5.5rem;
    top: 30rem;
    gap: 5rem;
    justify-content: center;
  }
}
.header__nav .list-container .menu-lang span {
  display: inline-block;
}
.header__nav .list-container .menu-lang .active {
  opacity: 0.5;
}
.header__nav .list-container .model-menu {
  position: absolute;
  right: 5%;
  top: 35% !important;
  transform: translateY(-50%);
  height: 50rem !important;
}
@media (max-width: 700px) {
  .header__nav .list-container .model-menu {
    right: unset;
    position: relative;
    top: 25% !important;
    width: 100% !important;
    height: 110rem !important;
  }
}
.header__nav .list-container .model-menu canvas {
  width: 100% !important;
  height: 100% !important;
}
.header__nav .menu-close {
  z-index: 99999999999;
  position: absolute;
  right: 2%;
  color: white;
  font-size: 3rem;
  line-height: 1;
  font-family: "text-medium";
  font-weight: 500;
  position: absolute;
  top: 5rem;
  cursor: pointer;
}
@media (max-width: 700px) {
  .header__nav .menu-close {
    font-size: 5rem;
    top: 6rem;
    right: 4rem;
  }
}
.header__nav .menu-close::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  top: 3.3rem;
  left: 0;
  transform: scaleX(0);
  background-color: white;
  transition: all 0.3s ease-in-out 0s;
  transform-origin: left;
}
@media (max-width: 700px) {
  .header__nav .menu-close::after {
    top: 5.5rem;
  }
}
.header__nav .menu-close:hover::after {
  transform: scaleX(1);
  transform: scaleY(1.1);
}
.header__nav .menu-close .nav-close-underline {
  align-self: flex-start;
  margin: 0;
  transform: translate(0, -15px);
  z-index: 700;
  display: inline-block;
  background-color: white;
}
.header__nav .menu__star {
  opacity: 1;
  position: absolute;
  top: 8%;
  right: 3.5%;
}
@media (max-width: 700px) {
  .header__nav .menu__star {
    top: 5.2%;
  }
}
.header__nav .nav-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  justify-content: space-between;
  align-items: center;
  height: 90%;
  z-index: 1111;
}
@media (max-width: 700px) {
  .header__nav .nav-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    margin-top: 40rem;
    height: 80%;
  }
}
.header__nav .nav-list .list-item {
  align-self: center;
  list-style: none;
  font-size: 16rem;
  font-family: "marco-medium";
  font-weight: 500;
  line-height: 0.75;
}
@media (max-width: 700px) {
  .header__nav .nav-list .list-item {
    font-size: 20rem;
  }
}
.header__nav .nav-list .list-item .items {
  text-decoration: none;
  color: rgb(255, 246, 30);
  cursor: pointer;
  font-family: "marco-medium";
  align-self: flex-start;
  position: relative;
  padding-left: 0.6rem;
}
@media (max-width: 700px) {
  .header__nav .nav-list .list-item .items {
    align-self: center;
  }
}
.header__nav .nav-list .list-item .items:nth-child(1):hover #path {
  opacity: 1;
  stroke-dasharray: 1679.4949951172;
  animation: drawPath 0.5s linear;
  transform: scaleY(0.8);
}
@keyframes drawPath {
  0% {
    stroke-dashoffset: 1679.4949951172;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.header__nav .nav-list .list-item .items:nth-child(2):hover #path {
  opacity: 1;
  stroke-dasharray: 1679.4949951172;
  animation: drawPath 0.5s linear;
  transform: scaleY(0.76);
}
@keyframes drawPath {
  0% {
    stroke-dashoffset: 1679.4949951172;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.header__nav .nav-list .list-item .items:nth-child(2):hover svg {
  top: 1.5rem;
}
.header__nav .nav-list .list-item .items:nth-child(3):hover #path {
  opacity: 1;
  stroke-dasharray: 1679.4949951172;
  animation: drawPath 0.5s linear;
  transform: translateY(-4rem) scaleY(1.5) !important;
}
@keyframes drawPath {
  0% {
    stroke-dashoffset: 1679.4949951172;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.header__nav .nav-list .list-item .items:nth-child(4):hover #path {
  opacity: 1;
  stroke-dasharray: 1679.4949951172;
  animation: drawPath 0.5s linear;
  transform: scaleY(0.8);
}
@keyframes drawPath {
  0% {
    stroke-dashoffset: 1679.4949951172;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.header__nav .nav-list .list-item .lines {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.header__nav .nav-list .lines-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 700px) {
  .header__nav .nav-list .lines-container {
    justify-content: center;
    align-items: center;
  }
}

.color-yallow {
  margin-top: 4rem;
}
.color-yallow li {
  margin-bottom: 0.5rem;
  text-align: left !important;
}
@media (max-width: 700px) {
  .color-yallow li {
    font-size: 5rem;
  }
}
.color-yallow a {
  color: rgb(255, 246, 30) !important;
}
.color-yallow a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  top: 2.5rem;
  left: 0;
  background-color: rgb(255, 246, 30) !important;
  transition: all 0.3s ease-in-out 0s;
}
.color-yallow a:hover::after {
  transform: scaleX(1);
  width: 100%;
}

#path {
  opacity: 0;
}

@keyframes drawPath {
  0% {
    stroke-dashoffset: 1679.4949951172;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
* {
  zoom: 1;
  -webkit-text-size-adjust: none;
}

html::-webkit-scrollbar {
  display: none;
}

:root {
  touch-action: pan-y pinch-zoom;
  height: 100%;
}

html {
  font-size: 0.52085vw;
  -webkit-font-smoothing: antialiased;
  scrollbar-width: none; /* Firefox */
  --webkit-transform: translate3d(0, 0, 0);
}
@media (max-width: 700px) {
  html {
    font-size: 0.77vw;
  }
}

@media (max-width: 700px) {
  body {
    background-color: black;
  }
}

.contents {
  overflow-x: clip;
  background-color: rgb(255, 246, 30);
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

p {
  cursor: default;
}

video {
  will-change: transform;
}

.loading-page {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999999999 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4%;
  background-color: rgb(0, 0, 0);
}
.loading-page__text-container {
  position: relative;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: stretch;
  overflow: hidden;
  z-index: 1000;
}
.loading-page__text-container .spacer {
  background-color: black;
  position: absolute;
  width: 0%;
  height: 100%;
  z-index: 11111;
  right: 0;
}
@media (max-width: 700px) {
  .loading-page__text-container {
    top: 40%;
  }
}
.loading-page__title--1 {
  color: rgb(255, 246, 30);
  font-family: "marco-medium";
  font-weight: 500;
  font-size: 14rem;
}
@media (max-width: 700px) {
  .loading-page__title--1 {
    font-size: 20rem;
  }
}
.loading-page__title--2 {
  color: rgb(255, 246, 30);
  font-family: "marco-medium";
  font-weight: 500;
  font-size: 14rem;
}
@media (max-width: 700px) {
  .loading-page__title--2 {
    font-size: 20rem;
  }
}
.loading-page__text {
  color: white;
  margin-bottom: 1rem;
  z-index: 100;
  margin-top: auto;
  font-size: 2.5rem;
  font-family: "marco-light";
  font-weight: 300;
}
@media (max-width: 700px) {
  .loading-page__text {
    font-size: 5.5rem;
    margin-top: 100%;
  }
}

.main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 246, 30);
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 30rem;
  position: relative;
}
@media (max-width: 700px) {
  .main-section {
    padding-bottom: 5%;
    padding-left: 3rem;
    padding-right: 3rem;
    will-change: transform;
    transform: translateY(-5%);
  }
}
.main-section__level0-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10rem;
  margin-bottom: 4rem;
  overflow: hidden;
}
@media (max-width: 700px) {
  .main-section__level0-container {
    gap: 0px;
    will-change: transform;
  }
}
.main-section__level0-container .model-container {
  height: 60rem;
  width: 100rem;
  top: 5%;
  left: 33%;
  z-index: 99 !important;
  position: absolute;
}
@media (max-width: 700px) {
  .main-section__level0-container .model-container {
    height: 115rem;
    width: 100%;
    left: -1%;
  }
}
.main-section__level0-container canvas {
  will-change: transform;
}
@media (max-width: 700px) {
  .main-section__level0-container canvas {
    position: relative !important;
  }
}
@media (max-width: 700px) {
  .main-section__level0-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-around;
    gap: 0;
    margin-bottom: 0px;
  }
}
.main-section__level0-container .spacer {
  width: 100%;
  position: relative;
}
@media (max-width: 700px) {
  .main-section__level0-container .spacer {
    height: 120rem;
  }
}
.main-section__level0-container p {
  font-size: 8rem;
  line-height: 0.8;
  font-weight: 500;
  padding-bottom: 2rem;
  will-change: transform;
  font-family: "marco-medium";
  padding-left: 1rem;
  letter-spacing: 4px;
  width: 70rem;
}
@media (max-width: 700px) {
  .main-section__level0-container p {
    font-size: 12rem;
    letter-spacing: 3px;
    width: 120rem;
    text-align: left;
    flex-basis: 2.5em;
  }
}
@media (max-width: 430px) {
  .main-section__level0-container p {
    flex-basis: 3em;
  }
}
.main-section__level1-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  transform: translateY(4rem);
  will-change: transform;
  overflow: hidden;
  width: 100%;
}
@media (max-width: 700px) {
  .main-section__level1-container {
    transform: translateY(6rem);
  }
}
.main-section__level1-container P {
  line-height: 1;
  font-family: "marco-medium";
  font-weight: 500;
}
.main-section__level1-container P .zero {
  font-size: 19rem;
  color: rgb(107, 34, 255);
  padding-left: 1rem;
  font-size: 24rem;
  padding-right: 6rem;
}
@media (max-width: 700px) {
  .main-section__level1-container P .zero {
    font-size: 22rem;
  }
}
.main-section__level1-container P.fuffa {
  font-size: 19rem;
  color: "";
  font-size: 24rem;
  font-weight: 500;
  padding-top: 5px;
}
@media (max-width: 700px) {
  .main-section__level1-container P.fuffa {
    font-size: 22rem;
  }
}
.main-section .level2-main-container {
  position: relative;
  align-self: flex-start;
}
.main-section .level2-main-container .star-container {
  position: absolute;
  width: 9rem;
  height: 9rem;
  right: -1.5rem;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 700px) {
  .main-section .level2-main-container .star-container {
    top: 60%;
    right: unset;
    left: 28%;
  }
}
.main-section .level2-main-container .loading-star {
  position: absolute;
  top: 0rem;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
@media (max-width: 700px) {
  .main-section .level2-main-container .loading-star {
    will-change: transform;
  }
}
@media (max-width: 700px) {
  .main-section .level2-main-container .loading-star {
    top: -4rem;
  }
}
.main-section .level2-main-container .loading-star svg {
  width: 8rem;
  height: 8rem;
  aspect-ratio: 1;
  animation: infiniteRotate 2s linear infinite;
  animation-delay: 4s;
  transform-origin: center;
}
@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-section .level2-main-container .loading-star--t {
  position: absolute;
  top: 0rem;
  width: 100%;
  height: 100%;
}
@media (max-width: 700px) {
  .main-section .level2-main-container .loading-star--t {
    top: -4rem;
  }
}
.main-section .level2-main-container .loading-star--t svg {
  width: 8rem;
  height: 8rem;
  aspect-ratio: 1;
  animation: infiniteRotate 2s linear infinite;
  transform-origin: center;
}
@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-section__level2-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  transform: translateY(-8%);
  justify-content: flex-start;
  gap: 3rem;
  width: 100%;
  will-change: transform;
  padding-bottom: 5rem;
  overflow: hidden;
}
@media (max-width: 700px) {
  .main-section__level2-container {
    padding-bottom: 0;
    transform: translateY(1rem);
  }
}
.main-section__level2-container p {
  line-height: 1;
  will-change: transform;
  font-family: "marco-medium";
  font-weight: 500;
}
.main-section__level2-container p.pro {
  font-size: 19rem;
  color: "";
  font-size: 24rem;
  will-change: transform;
  line-height: 0.25;
  padding-bottom: 13rem;
  padding-right: 1rem;
  margin-right: 9rem;
  font-weight: 500;
  display: inline-block;
}
@media (max-width: 700px) {
  .main-section__level2-container p.pro {
    line-height: 0.6 !important;
    padding-bottom: 7rem;
    margin-right: unset;
    padding-right: unset;
  }
}
.main-section__level2-container p.pro .hunderd {
  font-size: 19rem;
  color: rgb(107, 34, 255);
  padding-right: 10px;
  font-size: 24rem;
  display: inline-block;
  will-change: transform;
  line-height: 1.2;
  font-weight: 500;
}
@media (max-width: 700px) {
  .main-section__level2-container p.pro {
    font-size: 22rem;
    line-height: 1.2;
  }
}
@media (max-width: 700px) and (max-width: 700px) {
  .main-section__level2-container p.pro .hunderd {
    font-size: 22rem;
    line-height: 0.9;
    padding-right: 0px;
  }
}
.main-section__nested-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  overflow: hidden;
  align-self: flex-start;
  position: relative;
  will-change: transform;
  gap: 1rem;
}
.main-section__nested-container img.small-star {
  align-self: flex-start;
  display: inline-block;
  text-align: right;
  width: 3rem;
  height: 3rem;
  will-change: transform;
}
@media (max-width: 700px) {
  .main-section__nested-container img.small-star {
    width: 5rem;
    height: 5rem;
  }
}
.main-section__nested-container img.rotate {
  animation: infiniteRotate 2s linear infinite;
}
@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-section__nested-container p {
  will-change: transform;
}
.main-section__nested-container p.text {
  font-size: 6rem;
  color: rgb(107, 34, 255);
  text-align: left;
  font-family: "marco-medium";
  font-weight: 500;
  letter-spacing: 0.7rem;
  line-height: 5.5rem;
  align-self: center;
  display: inline-block;
  width: -moz-min-content;
  width: min-content;
  will-change: transform;
}
@media (max-width: 700px) {
  .main-section__nested-container p.text {
    font-size: 10rem !important;
    line-height: 9rem;
  }
}

.section__container {
  background-color: rgb(107, 34, 255);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 450vh;
  justify-content: flex-start;
  position: relative;
  z-index: 999;
  will-change: transform;
}
.section__container .section-sticky {
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: -5vh;
  will-change: transform;
}
@media (max-width: 700px) {
  .section__container .section-sticky {
    top: 10vh;
  }
}
@media (max-height: 1000px) and (min-width: 1200px) {
  .section__container .section-sticky {
    top: -5%;
  }
}
@media (max-height: 1390px) and (max-width: 1200px) {
  .section__container .section-sticky {
    top: 30%;
  }
}
@media (max-height: 1000px) and (max-width: 1200px) {
  .section__container .section-sticky {
    top: 10%;
  }
}
.section__container-paragraph {
  will-change: transform;
  font-size: 100em;
  text-indent: -4px;
  font-family: "marco-medium";
  font-weight: 500;
  color: rgb(255, 246, 30);
  position: relative;
}
@media (max-width: 700px) {
  .section__container-paragraph {
    font-size: 200em;
  }
}
.section__container .note {
  font-size: 4em;
}
@media (max-width: 700px) {
  .section__container .note {
    font-size: 10em;
  }
}
.section__container .note .white-star {
  width: 0.7em;
  height: 0.7em;
}
.section__container .note:nth-child(1) {
  top: 20%;
  left: 3%;
  rotate: -21.36deg;
}
@media (max-width: 600px) {
  .section__container .note:nth-child(1) {
    top: 32%;
  }
}
.section__container .note:nth-child(2) {
  bottom: 22%;
  left: 15%;
  rotate: 21.36deg;
}
@media (max-width: 600px) {
  .section__container .note:nth-child(2) {
    bottom: 32%;
  }
}
.section__container .note:nth-child(3) {
  top: 32%;
  left: 30%;
  rotate: -21.36deg;
}
.section__container .note:nth-child(4) {
  bottom: 32%;
  left: 40%;
  rotate: 21.36deg;
}
.section__container .note:nth-child(5) {
  top: 32%;
  left: 60%;
  rotate: -21.36deg;
}
.section__container .note:nth-child(6) {
  bottom: 32%;
  left: 80%;
  rotate: -21.36deg;
}
.section__container .note:nth-child(7) {
  top: 34%;
  left: 90%;
  rotate: 21.36deg;
}
@media (max-width: 600px) {
  .section__container .note:nth-child(7) {
    top: 40%;
  }
}

.stratogy__container {
  width: 100%;
  padding: 20rem 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 111;
  position: relative;
  top: 0;
  background-color: rgb(255, 246, 30);
}
@media (max-width: 700px) {
  .stratogy__container {
    height: 600vh !important;
    padding-left: 7rem;
    padding-right: 7rem;
    padding-bottom: 0;
  }
}
.stratogy__container .items-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 19rem;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}
@media (max-width: 700px) {
  .stratogy__container .items-container {
    position: sticky !important;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto !important;
    gap: 13rem;
  }
}
.stratogy__container .word-list {
  list-style: none;
  transform: translateX(10rem);
  will-change: transform;
}
@media (max-width: 700px) {
  .stratogy__container .word-list {
    transform: translate(0) !important;
  }
}
@media (max-width: 700px) {
  .stratogy__container .word-list li {
    transform: translate(0) !important;
  }
}
@media (max-width: 700px) {
  .stratogy__container .word-list {
    position: "relative";
    top: 0;
    margin-top: 0;
  }
}
.stratogy__container .word-list .first-title {
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: rgb(107, 34, 255);
  width: 100%;
  height: 100%;
}
.stratogy__container .word-list h3 {
  font-size: 16rem;
  will-change: transform;
  font-family: "marco-medium";
  font-weight: 500;
  line-height: 0.8;
  letter-spacing: 0.6rem;
  font-family: "marco-medium";
  padding: 0 1.6rem 3rem 1.6rem;
}
@media (max-width: 700px) {
  .stratogy__container .word-list h3 {
    font-size: 20rem;
  }
}
.stratogy__container .word-list .second-title {
  overflow: hidden;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
}
.stratogy__container .stratogy-left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  flex: 1;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 40%;
  gap: 3rem;
  overflow: hidden;
}
@media (max-width: 700px) {
  .stratogy__container .stratogy-left-side {
    margin-bottom: 0;
    position: relative;
    top: 0;
    margin-bottom: 0;
    margin-top: 0;
    flex-basis: 50%;
  }
}
.stratogy__container .stratogy-text {
  font-size: 2.4rem;
  line-height: 1.2;
  width: 60rem;
  font-family: "text-regular";
  font-weight: 400;
}
@media (max-width: 700px) {
  .stratogy__container .stratogy-text {
    font-size: 5.5rem;
    width: 100%;
  }
}
.stratogy__container .stratogy-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.stratogy__container .stratogy-text-container .stratogy-img {
  animation: infiniteRotate 2s linear infinite;
  width: 2rem;
  margin-top: 0.7rem;
  display: none;
}
@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 700px) {
  .stratogy__container .stratogy-text-container .stratogy-img {
    width: 4rem;
  }
}
.stratogy__container .stratogy-text-container .stratogy-short-text {
  color: rgb(107, 34, 255);
  font-size: 4rem;
  font-family: "text-medium";
  font-weight: 500;
}
@media (max-width: 700px) {
  .stratogy__container .stratogy-text-container .stratogy-short-text {
    font-size: 7rem;
  }
}

.contact {
  background-color: rgb(0, 0, 0);
  padding: 16.8rem 12rem 16.8rem 12rem;
  position: relative;
  z-index: 111;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: stretch;
}
@media (max-width: 700px) {
  .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px 7rem 20rem 7rem;
    gap: 8rem;
  }
}
.contact__first-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  margin-right: auto;
  gap: 5.6rem;
  flex-basis: 65%;
}
@media (max-width: 700px) {
  .contact__first-wrapper {
    gap: 8rem;
    width: 100%;
  }
}
.contact__first-wrapper .question-container {
  overflow: hidden;
}
.contact__first-wrapper .question-container .question {
  font-size: 16rem;
  color: rgb(255, 246, 30);
  font-family: "marco-medium";
  font-weight: 500;
  line-height: 0.75;
}
.contact__first-wrapper .question-container .question span {
  padding-bottom: 2.1rem;
}
@media (max-width: 700px) {
  .contact__first-wrapper .question-container .question {
    font-size: 18rem;
  }
}
.contact__first-wrapper p {
  color: white;
  font-size: 4rem;
  font-family: "text-medium";
  font-weight: 500;
}
@media (max-width: 700px) {
  .contact__first-wrapper p {
    font-size: 8rem;
  }
}
.contact__first-wrapper .inner-container {
  overflow: hidden;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  cursor: pointer;
}
@media (max-width: 700px) {
  .contact__first-wrapper .inner-container {
    font-size: 5.5rem;
  }
}
.contact__first-wrapper .inner-container .small-white-star {
  animation: infiniteRotate 2s linear infinite;
  width: 2rem;
  align-self: flex-start;
  margin-top: 2.7rem;
  margin-left: 0.8rem;
}
@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 700px) {
  .contact__first-wrapper .inner-container .small-white-star {
    width: 3rem;
  }
}
.contact__second-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40rem;
  gap: 4rem;
  align-items: flex-end;
}
@media (max-width: 700px) {
  .contact__second-wrapper {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}
.contact__second-wrapper .spacer-contact {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 111;
  transform-origin: bottom;
}
.contact__second-wrapper .cat-img {
  width: 70%;
}
@media (max-width: 700px) {
  .contact__second-wrapper .cat-img {
    display: none;
  }
}
.contact__second-wrapper p {
  color: white;
  font-size: 4rem;
  align-self: flex-end;
  text-align: right;
  font-weight: 500;
  font-family: "text-medium";
  display: inline-block !important;
  width: 51%;
  line-height: 0.9;
  padding-bottom: 0.1rem;
}
@media (max-width: 700px) {
  .contact__second-wrapper p {
    text-align: left;
  }
}
.contact__second-wrapper .inner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  overflow: hidden;
  justify-content: stretch;
  height: 7.5rem;
}
@media (max-width: 700px) {
  .contact__second-wrapper .inner-container {
    justify-content: flex-end;
    margin-right: auto;
    height: auto;
  }
}
@media (max-width: 700px) {
  .contact__second-wrapper .inner-container span {
    font-size: 8rem;
  }
}
.contact__second-wrapper .inner-container img {
  animation: infiniteRotate 2s linear infinite;
  width: 2rem;
  margin-right: 0.8rem;
}
@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 700px) {
  .contact__second-wrapper .inner-container img {
    width: 3rem;
  }
}
.contact__second-wrapper .inner-container .flex-start {
  align-self: flex-start;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6.4rem 6.4rem 0rem 6.4rem;
  gap: 16.8rem;
  background-color: rgb(255, 246, 30);
  position: relative;
}
.footer__upper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}
@media (max-width: 700px) {
  .footer__upper {
    align-items: flex-start;
  }
}
.footer__upper .upper-left-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  gap: 8rem;
}
@media (max-width: 700px) {
  .footer__upper .upper-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
  }
}
.footer__upper .policy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  gap: 0.7rem;
}
@media (max-width: 700px) {
  .footer__upper .policy {
    align-items: flex-start;
    font-size: 6rem;
    gap: 4rem;
  }
}
.footer__upper .policy a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 2.5rem;
  font-family: "marco-light";
  line-height: 2.88rem;
  font-family: "text-regular";
  font-weight: 400;
  position: relative;
  padding-bottom: 0.57rem;
  display: block;
  line-height: 1;
  position: relative;
}
@media (max-width: 700px) {
  .footer__upper .policy a {
    font-size: 6rem;
    padding-bottom: 0.7rem;
  }
}
.footer__upper .policy a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: black;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  transform-origin: left;
}
@media (max-width: 700px) {
  .footer__upper .policy a::before {
    top: 6.5rem;
  }
}
.footer__upper .policy a:hover::before {
  transform: scaleX(1);
  transform: scaleY(0.7);
}
.footer__upper .policy .company-number {
  text-align: right;
  color: rgb(0, 0, 0);
  font-size: 2.5rem;
  font-family: "marco-light";
  line-height: 2.88rem;
}
@media (max-width: 700px) {
  .footer__upper .policy .company-number {
    text-align: left;
    font-size: 5rem;
    line-height: 1.3;
  }
}
@media (max-width: 700px) and (max-width: 700px) {
  .footer__upper .policy .company-number {
    font-size: 6rem;
  }
}
.footer__upper .policy .company-number p {
  font-family: "text-regular";
}
.footer__upper .policy .company-number h3 {
  padding-top: 4rem;
  font-weight: normal;
  font-family: "text-regular";
}
.footer__menu-list {
  font-size: 6rem;
}
.footer__menu-list li {
  list-style: none;
  line-height: 1;
  cursor: pointer;
  height: -moz-min-content;
  height: min-content;
  overflow: hidden;
}
.footer__menu-list li:hover .second-item {
  transform: translateY(0);
}
.footer__menu-list li:hover .second-item a {
  transform: translateY(0);
}
.footer__menu-list li .second-item {
  overflow: hidden;
  transform: translateY(100%);
  transition: transform 0.5s ease;
  letter-spacing: 0.2rem;
  font-family: "marco-medium";
}
.footer__menu-list li .second-item a {
  display: inline-block;
  transform: translateY(-100%);
  text-decoration: none;
  transition: transform 0.5s ease;
  color: rgb(107, 34, 255);
  padding: 0px 1rem;
}
@media (max-width: 700px) {
  .footer__menu-list li .second-item a {
    font-size: 11rem;
    letter-spacing: 0.9rem;
  }
}
.footer__menu-list li .first-item {
  position: absolute;
  font-family: "marco-medium";
  letter-spacing: 0.2rem;
}
@media (max-width: 700px) {
  .footer__menu-list li .first-item {
    font-size: 11rem;
    letter-spacing: 0.9rem;
  }
}
.footer__menu-list li .first-item p {
  padding: 0px 1rem;
}
.footer .fotonika-logo-container {
  width: 100%;
  height: 100%;
  margin-bottom: 8rem;
}

@keyframes color-up {
  100% {
    transform: translateY(0%);
  }
}
.cursor {
  position: fixed;
  z-index: 99999999999;
  pointer-events: none;
  overflow: hidden;
}
@media (max-width: 1000px) {
  .cursor {
    display: none;
  }
}
.cursor .small-star {
  width: 100%;
  height: 100%;
  animation: infiniteRotate 2s linear infinite;
}
@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cursor .text-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 1111;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.cursor .text-content-container img {
  width: 3rem;
  aspect-ratio: 1;
}
.cursor .text-content-container span {
  color: #5df814;
  font-family: "text-medium";
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  font-size: 2.4rem;
}

.pointer {
  z-index: 99999999999;
  width: 16.8rem;
  aspect-ratio: 168/168;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  background-color: rgb(107, 34, 255);
}
@media (max-width: 700px) {
  .pointer {
    display: none;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 246, 30);
  padding-top: 18rem;
  gap: 8rem;
}
@media (max-width: 700px) {
  .main {
    padding-top: 28rem;
  }
}
.main__dream-big-container {
  font-size: 35rem;
  white-space: nowrap;
  width: 100%;
  position: relative;
  gap: 40rem;
  overflow: hidden;
}
.main__dream-big-container .marquee-container {
  overflow: hidden;
}
.main__dream-big-container .dream-big-one {
  display: inline-block;
  padding-left: 10rem;
  position: relative;
  font-family: "marco-medium";
  font-weight: 500;
}
.main__text-container {
  width: 100%;
  font-size: 16rem;
  font-family: "marco-medium";
  font-weight: 500;
  max-width: 88%;
  padding-bottom: 16rem;
  flex: 1;
  position: relative;
}
@media (max-width: 700px) {
  .main__text-container {
    max-width: 100%;
    padding-left: 7rem;
    font-size: 21rem;
  }
}
.main__text-container .text {
  word-break: keep-all;
}
.main__text-container .hidden {
  overflow: hidden;
  padding-left: 0.6rem;
  display: flex;
  word-break: keep-all;
}
.main__text-container .hidden .slide-s {
  display: inline-block;
  line-height: 0.75;
  padding: 1rem 26rem 1rem 0rem;
  word-break: keep-all;
}
@media (max-width: 700px) {
  .main__text-container .hidden .slide-s {
    line-height: 0.8;
    padding: 1rem 7rem 1rem 0rem;
  }
}
.main__text-container .hidden:last-child {
  padding-bottom: 2.2rem;
}

.input-container {
  padding: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  height: 15rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  background-color: rgb(107, 34, 255);
}
@media (max-width: 700px) {
  .input-container {
    height: 35rem;
  }
}
.input-container .center-input {
  position: relative;
  z-index: 1111;
  width: 100%;
}
@media (max-width: 700px) {
  .input-container .center-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
  }
}
.input-container .center-input p {
  font-family: "text-regular";
  font-size: 2.4rem;
  position: absolute;
  top: 5rem;
  right: 0;
  flex: 0.2;
  text-align: right;
  color: white;
}
@media (max-width: 700px) {
  .input-container .center-input p {
    font-size: 5rem;
    text-align: left;
    white-space: nowrap;
    position: relative;
    top: auto;
    left: auto;
  }
}
.input-container .input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.input-container .input-wrapper P::first-letter {
  text-transform: capitalize;
}
.input-container .input-label {
  display: block;
  font-size: 2.5rem;
  color: white;
  font-family: "text-regular";
  font-weight: 400;
}
@media (max-width: 700px) {
  .input-container .input-label {
    font-size: 5.5rem;
  }
}
.input-container textarea {
  width: 100%;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  overflow: hidden; /* Prevents the scroll bar from appearing */
  resize: none;
  margin-top: 1.5rem;
}
@media (max-width: 700px) {
  .input-container textarea {
    margin-top: 4.5rem;
  }
}
.input-container .input,
.input-container textarea {
  border: none;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: rgb(107, 34, 255);
  font-size: 4rem;
  font-family: "text-medium";
  font-weight: 500;
  color: white;
  align-self: center;
  padding: 0; /* Ensure no padding interference */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 700px) {
  .input-container .input,
  .input-container textarea {
    font-size: 8rem;
  }
}
.input-container .input:focus,
.input-container textarea:focus {
  outline: none;
}
.input-container .input::-moz-placeholder, .input-container textarea::-moz-placeholder {
  color: white;
  white-space: wrap;
}
.input-container .input::placeholder,
.input-container textarea::placeholder {
  color: white;
  white-space: wrap;
}

.input-container:last-child {
  height: 100%;
}

.form-container {
  background-color: rgb(0, 0, 0);
  padding: 16.8rem 12rem 0px 12rem;
}
@media (max-width: 700px) {
  .form-container {
    padding: 16.8rem 7rem 0px 7rem;
  }
}
.form-container .form-title-container {
  overflow: hidden;
  margin-bottom: 6rem;
}
@media (max-width: 700px) {
  .form-container .form-title-container {
    margin-bottom: 10rem;
  }
}
.form-container .form-title {
  font-size: 6rem;
  font-family: "text-medium";
  font-weight: 500;
  color: white;
  text-align: left;
  line-height: 1;
  padding-bottom: 1rem;
}
@media (max-width: 700px) {
  .form-container .form-title {
    font-size: 10rem;
  }
}
.form-container .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
  margin-bottom: 6rem;
}
.form-container .btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 500px) {
  .form-container .btn-container {
    justify-content: flex-start;
    margin-left: unset;
  }
}
.form-container .btn-container span {
  color: white;
  font-size: 4rem;
  font-family: "text-medium";
  font-weight: 500;
}
@media (max-width: 700px) {
  .form-container .btn-container span {
    font-size: 7rem;
  }
}
.form-container .bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5rem;
}
@media (max-width: 700px) {
  .form-container .bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6rem;
    width: 100%;
    margin-top: 10rem;
  }
}
.form-container .bottom-container .privacy-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.form-container .bottom-container .privacy-container p {
  font-size: 2.4rem;
  font-family: "text-regular";
  line-height: 1.2;
  font-weight: 400;
  color: white;
  width: 43rem;
}
@media (max-width: 700px) {
  .form-container .bottom-container .privacy-container p {
    font-size: 5rem;
    width: 90%;
  }
}
.form-container .bottom-container .privacy-container p a {
  color: white;
  display: inline-block;
  cursor: pointer;
}
.form-container .bottom-container .privacy-container .privacy-clr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  transition: background 0.5s ease-out;
  cursor: pointer;
  margin-right: 1.5rem;
}
@media (max-width: 700px) {
  .form-container .bottom-container .privacy-container .privacy-clr {
    width: 13rem;
    height: 13rem;
  }
}
.form-container .bottom-container .privacy-container .privacy-clr svg {
  width: 5rem;
  aspect-ratio: 1;
}
.form-container .bottom-container .privacy-container .privacy-clr:hover {
  background-color: rgb(255, 246, 30);
}

.crl-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(107, 34, 255);
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  margin-right: 2.4rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
@media (max-width: 700px) {
  .crl-btn {
    width: 13rem;
    height: 13rem;
  }
}
.crl-btn::after {
  content: "";
  width: 13rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgb(93, 248, 20);
  position: absolute;
  top: 100%;
  transition: top 0.3s ease-out;
}
.crl-btn:hover::after {
  top: 0;
}
.crl-btn:hover > img {
  filter: invert(71%) sepia(86%) saturate(5764%) hue-rotate(164deg) brightness(99%) contrast(101%);
  width: 4rem;
}
@media (max-width: 700px) {
  .crl-btn:hover > img {
    width: 6rem;
  }
}
.crl-btn svg {
  width: 3.5rem;
  z-index: 11;
  transition: width 0.3s ease-out;
}
@media (max-width: 700px) {
  .crl-btn svg {
    width: 5rem;
  }
}

.location {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20rem 12rem 35rem 12rem;
  align-items: flex-start;
  background-color: rgb(0, 0, 0);
}
@media (max-width: 700px) {
  .location {
    padding: 20rem 7rem 30rem 7rem;
  }
}
@media (max-width: 700px) {
  .location {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    gap: 10rem;
    padding-bottom: 84rem;
  }
}
.location .hide {
  overflow: hidden;
  width: 100%;
}
.location .left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-items: flex-start;
  gap: 5rem;
  color: white;
  font-family: "text-medium";
  font-weight: 500;
  width: 100%;
}
.location .left-container .location-title {
  font-size: 7rem;
  line-height: 1;
  font-family: "milgram-medium";
  line-height: 0.9;
  font-weight: 500;
  width: 55rem;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
}
@media (max-width: 700px) {
  .location .left-container .location-title {
    font-size: 9rem;
    width: 80rem;
  }
}
.location .left-container .address {
  font-size: 4rem;
  width: 50rem;
  font-family: "text-medium";
  line-height: 0.9;
  font-weight: 500;
}
@media (max-width: 700px) {
  .location .left-container .address {
    font-size: 7rem;
    width: 80rem;
  }
}
.location .left-container .phone-number {
  font-size: 4rem;
  font-family: "text-medium";
  font-weight: 500;
  line-height: 0.9;
}
@media (max-width: 700px) {
  .location .left-container .phone-number {
    font-size: 7rem;
  }
}
.location .left-container .color-white {
  margin-top: 4rem;
  font-family: "text-medium";
  font-weight: 500;
}
.location .left-container .color-white li {
  text-align: left;
}
.location .left-container .color-white a {
  color: rgb(255, 246, 30);
}
@media (max-width: 700px) {
  .location .left-container .color-white a {
    line-height: 1.5;
  }
}
.location .left-container .color-white a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  top: 2.5rem;
  background-color: rgb(255, 246, 30);
  transition: all 0.3s ease-in-out 0s;
}
.location .left-container .color-white a:hover::before {
  transform: scaleX(1);
  width: 100%;
}
.location .direction-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  gap: 0.8rem;
  cursor: pointer;
}
.location .direction-container:hover .left-arrow {
  transform: scaleX(1.5) !important;
}
.location .direction-container .search {
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
}
@media (max-width: 700px) {
  .location .direction-container .search {
    font-size: 4rem;
  }
}
.location .direction-container .left-arrows {
  align-self: flex-start;
  transform-origin: left;
}
@media (max-width: 400px) {
  .location .direction-container .left-arrows {
    width: 4rem;
  }
}
.location .right-container {
  flex: 1;
  position: relative;
}
@media (max-width: 700px) {
  .location .right-container {
    width: 100%;
  }
}
.location .right-container .location-map-container .spacer {
  position: absolute;
  transform-origin: bottom;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
}
.location .right-container .location-map {
  width: 100%;
}
.location .right-container .visual-location {
  position: absolute;
  left: -40%;
  bottom: -15%;
  width: 70%;
  z-index: 11;
}
@media (max-width: 700px) {
  .location .right-container .visual-location {
    left: 10%;
    bottom: -45%;
    width: 80%;
  }
}

.direction-container {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  font-family: "text-medium";
  font-weight: 500;
  gap: 0.5rem !important;
  cursor: pointer;
}
@media (max-width: 700px) {
  .direction-container {
    gap: 2rem !important;
  }
}
.direction-container:hover .left-arrow {
  transform: scaleX(1.5) !important;
  transition: transform 0.5s;
}
.direction-container .search {
  font-size: 2rem;
  text-decoration: none;
}
@media (max-width: 700px) {
  .direction-container .search {
    font-size: 6rem !important;
  }
}
.direction-container .left-arrow {
  align-self: flex-start;
  transition: transform 0.5s;
  transform-origin: left;
  width: 3rem !important;
  align-self: center !important;
}
@media (max-width: 700px) {
  .direction-container .left-arrow {
    padding-top: 0.3rem;
    width: 6rem !important;
  }
}

.social-media {
  font-size: 2.5rem;
  font-family: "text-bold";
  text-transform: uppercase;
  list-style: none;
}
@media (max-width: 650px) {
  .social-media {
    line-height: 1.2;
  }
}
.social-media li {
  text-align: right;
  font-family: "text-medium";
  font-weight: 500;
  padding-bottom: 0.8rem;
  height: 100%;
  cursor: pointer;
}
.social-media li:last-child {
  padding-bottom: 0;
}
@media (max-width: 650px) {
  .social-media li {
    text-align: left;
  }
}
.social-media li a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  height: 100%;
  position: relative;
}
@media (max-width: 650px) {
  .social-media li a {
    font-size: 6rem;
  }
}
.social-media li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 2.8rem;
  background-color: black;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  transform-origin: left;
}
@media (max-width: 700px) {
  .social-media li a::after {
    top: 6.5rem;
  }
}
.social-media li a:hover::after {
  transform: scaleX(1);
  transform: scaleY(0.8);
}

.slide-in {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 246, 30);
  z-index: 9999;
}
.slide-in img {
  width: 65rem;
  font-size: 14rem;
  font-family: "marco-medium";
  font-weight: 500;
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 246, 30);
  transform-origin: top;
  z-index: 9999;
}
.slide-out img {
  width: 65rem;
  font-size: 14rem;
  font-family: "marco-medium";
  font-weight: 500;
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-container {
  padding: 6.4rem;
  width: 100%;
}
@media (max-width: 700px) {
  .card-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 10rem;
  }
}
.card-container .card-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: stretch;
}
@media (max-width: 700px) {
  .card-container .card-holder {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    align-items: flex-start;
  }
}
.card-container .card-holder .card-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.5rem;
}
@media (max-width: 700px) {
  .card-container .card-holder .card-column {
    gap: 4rem;
  }
}
.card-container .card-holder .card-column .card-topper {
  font-size: 2.4rem;
  font-family: "text-regular";
  line-height: 1.2;
  font-weight: 400;
  color: rgb(0, 0, 0);
}
@media (max-width: 700px) {
  .card-container .card-holder .card-column .card-topper {
    font-size: 5rem;
  }
}
.card-container .card-holder .card-middler {
  font-family: "milgram-medium";
  font-weight: 500;
  font-size: 6rem;
  width: 80%;
  line-height: 0.9;
}
@media (max-width: 700px) {
  .card-container .card-holder .card-middler {
    font-size: 11rem;
    width: 100%;
  }
}
.card-container .card-holder .card-bottomer {
  font-size: 2.4rem;
  font-family: "text-regular";
  font-weight: 400;
  color: rgb(0, 0, 0);
  width: 80%;
}
@media (max-width: 700px) {
  .card-container .card-holder .card-bottomer {
    font-size: 5.5rem;
    width: 100%;
  }
}
.card-container .card-holder .card-btn {
  font-size: 2rem;
  font-family: "marco-medium";
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}
@media (max-width: 700px) {
  .card-container .card-holder .card-btn {
    font-size: 5.5rem;
    margin-top: 2rem;
  }
}
.card-container .card-holder .card-img {
  flex: 0.5;
  overflow: hidden;
  aspect-ratio: 441.281/443.281;
}
@media (min-width: 700px) {
  .card-container .card-holder .card-img {
    width: 44.1281rem;
    aspect-ratio: 441.281/443.281;
  }
}
.card-container .card-holder .card-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: scale 0.8s ease-out;
  cursor: pointer;
}
.card-container .card-holder .card-img img:hover {
  scale: 1.1;
}

.blog {
  padding: 16.8rem 25rem 20rem 25rem;
  background-color: rgb(0, 0, 0);
  z-index: 111;
  height: 290rem;
  will-change: height;
  position: relative;
  padding-bottom: 50rem;
}
@media (max-width: 700px) {
  .blog {
    padding: 16.8rem 10rem 210rem 10rem;
    height: 600rem;
  }
}
.blog .blog-holder {
  position: sticky;
  top: 0;
}
.blog h3 {
  font-size: 50rem;
  font-family: "marco-medium";
  font-weight: 500;
  color: rgb(255, 246, 30);
  text-align: center;
  position: relative;
  margin-bottom: 10rem;
  position: sticky;
  top: 0;
}
.blog .yellow {
  background-color: rgb(255, 246, 30);
  position: absolute;
  top: 50%;
}
.blog .purple {
  background-color: rgb(107, 34, 255);
  position: absolute;
  top: 50%;
  color: white !important;
}
.blog .purple .card-topper {
  color: white !important;
}
.blog .purple .card-bottomer {
  color: white !important;
}
.blog .green {
  background-color: white;
  position: absolute;
  top: 50%;
}

.blog__btn-wrapper {
  width: 100%;
  padding-top: 2rem;
  background-color: rgb(0, 0, 0);
  z-index: 99;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 700px) {
  .blog__btn-wrapper {
    padding-top: 10rem;
    padding-bottom: 20rem;
  }
}
.blog__btn-wrapper :hover #path {
  opacity: 1;
  stroke-dasharray: 1679.4949951172;
  animation: drawPath 0.5s linear;
}
@keyframes drawPath {
  0% {
    stroke-dashoffset: 1679.4949951172;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.blog__btn-wrapper .blog__text-svg-wrapper {
  position: relative;
  width: 35rem;
}
@media (max-width: 700px) {
  .blog__btn-wrapper .blog__text-svg-wrapper {
    width: 50rem;
  }
}
.blog__btn-wrapper .blog__svg-btn {
  position: absolute;
  height: 100%;
}
.blog__btn-wrapper .blog__svg-btn svg {
  height: 100%;
  width: 100%;
}
.blog__btn-wrapper span {
  color: white;
  font-family: "text-medium";
  font-weight: 500;
  font-size: 4rem;
  text-align: center;
  display: inline-block;
  line-height: 0.9;
}
@media (max-width: 700px) {
  .blog__btn-wrapper span {
    font-size: 8rem;
  }
}

.approccia-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  background-color: rgb(255, 246, 30);
  gap: 10rem;
  padding: 0rem 12rem 10rem 12rem;
}
@media (max-width: 700px) {
  .approccia-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 7rem 16.8rem 7rem;
  }
}
.approccia-main .primary-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16rem;
  font-family: "marco-medium";
  font-weight: 500;
  position: relative;
  align-self: flex-start;
}
@media (max-width: 700px) {
  .approccia-main .primary-container {
    font-size: 20rem;
  }
}
.approccia-main .primary-container .slide-sss {
  align-self: center;
  display: inline-block;
  line-height: 0.8;
  padding: 0 1.6rem 1.7rem 1.6rem;
  align-self: center;
}
.approccia-main .primary-container .primary-text {
  color: rgb(0, 0, 0);
  height: 60rem;
}
.approccia-main .primary-container .primary-text .hidden {
  overflow: hidden;
  display: flex !important;
  flex-direction: row;
}
.approccia-main .primary-container .primary-text .inner-text-wrapper {
  position: relative;
  display: inline-flex;
}
.approccia-main .primary-container .primary-text .inner-text-wrapper .line-container {
  position: absolute;
  right: 0;
  top: 1rem;
  width: 100%;
  transform: scale(1.2, 1.1) !important;
  z-index: 11;
}
.approccia-main .primary-container .primary-text .inner-text-wrapper .line-container svg {
  width: 100%;
  height: 100%;
}
@media (max-width: 800px) {
  .approccia-main .primary-container .primary-text .inner-text-wrapper .line-container svg {
    height: auto !important;
  }
}
.approccia-main .primary-container .primary-text .inner-text-wrapper .approccio {
  color: rgb(107, 34, 255);
}
.approccia-main .primary-container .primary-text .btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-top: 2rem;
  cursor: pointer;
}
.approccia-main .secondary-text-container {
  position: relative;
  overflow: hidden;
  flex: 1;
  align-self: flex-end;
  margin-bottom: 10.4rem;
}
.approccia-main .secondary-text-container .spacerApp {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgb(255, 246, 30);
  transform-origin: bottom;
}
@media (max-width: 700px) {
  .approccia-main .secondary-text-container {
    margin-right: auto;
  }
}
.approccia-main .secondary-text {
  overflow: hidden;
  font-size: 2.4rem;
  font-family: "text-regular";
  font-weight: 400;
  line-height: 1.2;
  text-align: right;
  margin-left: auto;
}
@media (max-width: 700px) {
  .approccia-main .secondary-text {
    font-size: 5.33rem;
    text-align: left;
    align-self: flex-start;
  }
}

.intro-section {
  background-color: rgb(255, 246, 30);
  padding: 18rem 12rem 0rem 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
}
@media (max-width: 700px) {
  .intro-section {
    padding: 30rem 7rem 0rem 7rem;
    gap: 3rem;
  }
}
.intro-section .intro-top-wrapper {
  font-size: 23.4rem;
  font-family: "marco-medium";
  font-weight: 500;
  line-height: 0.8;
}
@media (max-width: 700px) {
  .intro-section .intro-top-wrapper {
    font-size: 21.4rem;
  }
}
.intro-section .intro-top-wrapper .intro-top-text .hidden {
  overflow: hidden;
  position: relative;
  z-index: 111;
}
.intro-section .intro-top-wrapper .intro-top-text .hidden .purpal-cover {
  position: relative;
  display: inline-flex;
  justify-content: center;
}
.intro-section .intro-top-wrapper .intro-top-text .hidden .slide-intro {
  line-height: 0.8;
  word-break: keep-all;
  display: inline-block;
  padding: 0 0rem 5rem 0.8rem;
  transform: translateY(110%);
}
.intro-section .intro-top-wrapper .intro-top-text .hidden svg {
  position: absolute;
  bottom: -15px;
  width: 100%;
  height: 100%;
  transform: scale(2) scaleX(0.6);
  z-index: 11;
}
@media (max-width: 700px) {
  .intro-section .intro-top-wrapper .intro-top-text .hidden svg {
    bottom: -6px;
  }
}
.intro-section .intro-top-wrapper .intro-top-text .hidden:nth-child(1) {
  z-index: 11;
}
.intro-section .intro-top-wrapper .intro-top-text .hidden:nth-child(2) {
  transform: translateY(-6rem);
}
.intro-section .intro-top-wrapper .intro-top-text .hidden:nth-child(3) {
  transform: translateY(-12rem);
}
.intro-section .intro-top-wrapper .intro-top-text .purpal-non {
  color: rgb(107, 34, 255);
  align-self: center;
}
.intro-section .intro-bottom-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16.8rem;
  padding: 0 3rem;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 700px) {
  .intro-section .intro-bottom-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    padding: 0 0rem;
  }
}
.intro-section .intro-bottom-wrapper .first-block-container {
  transform: translateY(100%);
  font: 500 6rem "marco-medium";
  width: 63rem;
  align-self: flex-end;
  letter-spacing: 0.4rem;
  padding-left: 1rem;
}
@media (max-width: 700px) {
  .intro-section .intro-bottom-wrapper .first-block-container {
    align-self: flex-start;
    width: 100%;
    font-size: 10rem;
  }
}
.intro-section .intro-bottom-wrapper .first-block-container .word-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.intro-section .intro-bottom-wrapper .first-block-container .b-word-container {
  white-space: nowrap;
  vertical-align: baseline;
}
.intro-section .intro-bottom-wrapper .first-block-container .b-word-container .b-word {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 0.3rem;
}
.intro-section .intro-bottom-wrapper .first-block-container .a-word-container {
  vertical-align: baseline;
  transform: translateY(100%);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
}
.intro-section .intro-bottom-wrapper .first-block-container .a-word-container .a-word {
  display: inline-block;
  color: rgb(107, 34, 255);
  transform: translateY(-100%);
  padding-bottom: 0.4rem;
  padding-left: 0.3rem;
}
.intro-section .intro-bottom-wrapper .first-block-container p {
  line-height: 0.9;
}
.intro-section .intro-bottom-wrapper .second-block-container {
  font: 500 2.4rem "text-regular";
  align-self: flex-end;
  width: 65rem;
  text-align: right;
  padding-left: 1rem;
  line-height: 1.2;
  padding-bottom: 0.1rem;
  transform: translateY(100%);
}
@media (max-width: 700px) {
  .intro-section .intro-bottom-wrapper .second-block-container {
    align-self: flex-start;
    width: 100%;
    font-size: 5.5rem;
    text-align: left;
  }
}

.message-container {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgb(93, 248, 20);
  z-index: 99999;
}
.message-container span {
  display: inline-block;
  padding: 2rem 4rem;
  font-size: 4rem;
  font-family: "marco-light";
  text-transform: uppercase;
}

.project {
  background-color: rgb(255, 246, 30);
  position: relative;
  padding: 10rem 0 25rem 0;
  position: relative;
  z-index: 99;
  color: rgb(255, 246, 30);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
@media (max-width: 700px) {
  .project {
    transform: translateY(-25rem);
    padding: 0rem 0 0rem 0;
  }
}
.project .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 25%;
}
@media (max-width: 700px) {
  .project .text-container {
    top: 50%;
    position: static;
  }
}
.project .text-container .spacer-text {
  height: 100%;
  width: 101%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 1111;
  transform-origin: bottom;
}
.project-title {
  position: relative;
  font-size: 30rem;
  z-index: 11;
  font-family: "marco-medium";
  font-weight: 500;
  text-align: center;
  margin-bottom: 5rem;
}
@media (max-width: 700px) {
  .project-title {
    line-height: 0.8;
    font-size: 37rem;
  }
}
.project-mini-text {
  position: relative;
  font-size: 6rem;
  z-index: 11;
  font-family: "marco-medium";
  font-weight: 400;
  letter-spacing: 3px;
}
@media (max-width: 700px) {
  .project-mini-text {
    font-size: 10rem;
  }
}
.project .star-container {
  position: absolute;
  bottom: 80%;
  transform: scale(0);
  transform-origin: 0 0;
  will-change: scale;
}
.project .main-block-two {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  justify-content: space-between;
  visibility: hidden;
  width: 100%;
  padding: 0 12rem 20rem 12rem;
}
@media (max-width: 700px) {
  .project .main-block-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0rem 12rem 0rem 12rem;
  }
}
.project .main-block-two .second-img-container {
  align-self: center;
  flex: 0.3;
  overflow: hidden;
}
.project .main-block-two .second-img-container .img-container {
  align-self: center;
  transform-origin: top;
  position: relative;
  z-index: 12;
  width: 100%;
  margin-left: auto;
  will-change: transform;
  will-change: transform;
  aspect-ratio: 1;
  overflow: hidden;
}
.project .main-block-two .second-img-container .img-container .spacer {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 11;
  transform-origin: bottom;
}
.project .main-block-two .second-img-container a {
  width: 100%;
  height: 100%;
}
.project .main-block-two .second-img-container a img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  scale: 1.1 !important;
}
.project .main-block-two .first-img-container {
  flex: 0.4;
}
.project .main-block-two .first-img-container .img-container {
  position: relative;
  z-index: 12;
  width: 100%;
  will-change: transform;
  aspect-ratio: 1;
  overflow: hidden;
  will-change: transform;
}
.project .main-block-two .first-img-container .img-container .spacer {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 11;
  transform-origin: bottom;
}
.project .main-block-two .first-img-container a {
  width: 100%;
  height: 100%;
}
.project .main-block-two .first-img-container a img {
  width: 100%;
  height: 100%;
  scale: 1.1 !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.project .text-cover {
  position: relative;
  font-family: "text-regular";
  font-weight: 500;
  line-height: 1.2;
}
.project .text-cover .spacer-title {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 99999;
  transform-origin: top;
}
.project .main-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0rem 12rem 20rem 12rem;
}
@media (max-width: 700px) {
  .project .main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10rem 12rem 0rem 12rem;
  }
}
.project .main-block .first-img-container {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
}
.project .main-block .first-img-container .img-container {
  position: relative;
  z-index: 12;
  width: 100%;
  will-change: transform;
  aspect-ratio: 1;
  overflow: hidden;
}
.project .main-block .first-img-container .img-container .spacer {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 11;
  transform-origin: bottom;
}
.project .main-block .first-img-container a {
  width: 100%;
  height: 100%;
}
.project .main-block .first-img-container a img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  scale: 1.1 !important;
}
.project .main-block .second-img-container {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
}
@media (max-width: 700px) {
  .project .main-block .second-img-container {
    align-items: flex-start;
  }
}
.project .main-block .second-img-container .img-container {
  position: relative;
  z-index: 12;
  width: 100%;
  will-change: transform;
  aspect-ratio: 1;
  overflow: hidden;
  will-change: transform;
}
.project .main-block .second-img-container .img-container .spacer {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 11;
  transform-origin: bottom;
}
.project .main-block .second-img-container a {
  width: 100%;
  height: 100%;
}
.project .main-block .second-img-container a img {
  width: 100%;
  height: 100%;
  scale: 1.1 !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.project .project__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 111;
  font-family: "text-medium";
  font-weight: 500;
  font-size: 4rem;
  transform: translateY(15rem);
  cursor: pointer;
}
@media (max-width: 700px) {
  .project .project__btn {
    font-size: 8.5rem;
    margin-bottom: 12rem;
  }
}
.project .project__btn span {
  display: inline-block;
  width: 25rem;
}
@media (max-width: 700px) {
  .project .project__btn span {
    width: 40%;
  }
}

.parall-title {
  position: relative;
  font-family: "text-medium";
  font-weight: 500;
  font-size: 2.4rem;
  color: white;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  z-index: 1111;
}
@media (max-width: 700px) {
  .parall-title {
    margin-top: 5rem;
    font-size: 6rem;
  }
}

.parall-text {
  position: relative;
  font-family: "text-regular";
  font-size: 2.4rem;
  color: white;
  z-index: 1111;
  line-height: 1.2;
  font-weight: normal;
}
@media (max-width: 700px) {
  .parall-text {
    font-size: 5.5rem;
    margin-bottom: 10rem;
  }
}

.not-found {
  background-color: rgb(107, 34, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 246, 30);
  height: 100vh;
  padding-left: 7rem;
  padding-right: 7rem;
}
.not-found .status-code {
  font-size: 65rem;
  height: -moz-min-content;
  height: min-content;
  width: -moz-min-content;
  width: min-content;
  font-family: "marco-medium";
  font-weight: 500;
  height: 63rem;
}
@media (max-width: 700px) {
  .not-found .status-code {
    font-size: 85rem;
    height: auto;
  }
}
.not-found .node-sticky {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.not-found .node-sticky .note .note__text {
  font-size: 5.5rem !important;
}
.not-found .node-sticky .note:nth-child(2) {
  top: 20%;
  left: 25%;
  rotate: -21.36deg;
}
.not-found .node-sticky .note:nth-child(3) {
  top: 66%;
  left: 25%;
  rotate: 10.36deg;
}
.not-found .node-sticky .note:nth-child(4) {
  top: 30%;
  left: 50%;
  rotate: -10.36deg;
}
.not-found .g {
  display: inline-block;
  font-size: 6rem;
  font-family: "text-medium";
  font-weight: 500;
  margin-bottom: 6.4rem;
  text-align: center;
}
@media (max-width: 700px) {
  .not-found .g {
    font-size: 8rem;
  }
}
.not-found a {
  position: relative;
  text-decoration: none;
  color: white;
  font-size: 4rem;
  font-family: "text-medium";
  font-weight: 500;
  transform: translateY(0rem);
}
@media (max-width: 700px) {
  .not-found a {
    font-size: 8rem;
  }
}
.not-found a:hover #path {
  opacity: 1;
  stroke-dasharray: 1679.4949951172;
  animation: drawPath 0.5s linear;
}
@keyframes drawPath {
  0% {
    stroke-dashoffset: 1679.4949951172;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.not-found a .home-link-svg {
  position: absolute;
  top: 0;
  width: 100%;
  transform: scaleY(1.5);
}
.not-found a .home-link-svg svg {
  width: 100%;
  z-index: 111111111;
}

.note {
  background-color: rgb(0, 0, 0);
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: white;
  gap: 1rem;
  border-radius: 5rem;
  font-family: "text-medium";
  font-weight: 500;
  will-change: transform;
  padding: 0.65em;
  line-height: 0.9;
  backface-visibility: hidden;
}
@media (max-width: 600px) {
  .note {
    font-size: 3rem;
    border-radius: 13rem;
  }
}
.note span {
  align-self: center;
  font-family: "text-medium";
  display: inline-block;
}
.note .white-star {
  width: 4rem;
  height: 4rem;
  rotate: -21.36deg;
  align-self: center;
  animation: infiniteRotate 2s linear infinite;
  will-change: transform;
  backface-visibility: hidden;
}
@keyframes infiniteRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.thank-you {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  height: 100vh;
  padding: 0rem 12rem 0rem 12rem;
}
@media (max-width: 700px) {
  .thank-you {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32.8rem 7rem 22rem 7rem;
    gap: 22rem;
    height: auto;
  }
}
.thank-you .left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  height: 100%;
  flex: 1;
}
@media (max-width: 700px) {
  .thank-you .left-container {
    gap: 11rem;
  }
}
.thank-you .left-container .title {
  font-size: 16rem;
  font-family: "marco-medium";
  font-weight: 500;
  color: rgb(255, 246, 30);
  line-height: 0.8;
  align-self: flex-start;
}
@media (max-width: 700px) {
  .thank-you .left-container .title {
    font-size: 20rem;
  }
}
.thank-you .left-container .paragraph {
  font-size: 2.4rem;
  font-family: "text-regular";
  font-weight: 400;
  width: 60.6rem;
  line-height: 1.2;
  align-self: flex-start;
  color: white;
}
@media (max-width: 700px) {
  .thank-you .left-container .paragraph {
    font-size: 6rem;
    width: 100%;
  }
}
.thank-you .left-container .btn-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
}
.thank-you .left-container .btn-text-container span {
  color: white;
  font-family: "text-medium";
  font-weight: 500;
  font-size: 4rem;
  display: inline-block;
}
@media (max-width: 700px) {
  .thank-you .left-container .btn-text-container span {
    font-size: 7rem;
  }
}
@media (max-width: 700px) {
  .thank-you .left-container .btn-text-container .crl-btn {
    scale: 1.4;
    margin-right: 5rem;
    width: 9rem !important;
    height: 9rem !important;
  }
}
.thank-you .left-container .btn-text-container .crl-btn svg {
  transform: rotate(180deg);
  width: 4rem !important;
}
.thank-you .right-container {
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
}
@media (max-width: 700px) {
  .thank-you .right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.thank-you .right-container img {
  width: 60%;
  aspect-ratio: 1;
}
@media (max-width: 700px) {
  .thank-you .right-container img {
    width: 100%;
  }
}

.main-project {
  padding: 18rem 12rem 0rem 12rem;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 700px) {
  .main-project {
    padding: 28rem 7rem 6.4rem 7rem;
  }
}
.main-project .project__title-container {
  overflow-y: hidden;
  margin-bottom: 4rem;
  align-self: flex-start;
  padding-right: 1rem;
}
.main-project h3 {
  font-size: 16rem;
  color: rgb(255, 246, 30);
  font-family: "marco-medium";
  font-weight: 500;
  transform: translateY(140%);
}
@media (max-width: 700px) {
  .main-project h3 {
    font-size: 20rem;
    width: 90rem;
    text-align: left;
    margin-bottom: 10rem;
  }
}
.main-project .spacer-p {
  height: 102%;
  width: 101%;
  transform: scaleY(1);
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 11;
  bottom: 0;
}
.main-project .project-collection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  align-self: flex-start;
  width: 100%;
  height: 100%;
}
@media (max-width: 700px) {
  .main-project .project-collection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.main-project .project__section-container {
  width: calc(50% - 4rem);
  margin-bottom: 12rem;
}
@media (max-width: 700px) {
  .main-project .project__section-container {
    width: 100%;
  }
}
.main-project .project__section-container .project__img-container {
  width: 100%;
  position: relative;
  aspect-ratio: 1;
  margin-bottom: 3rem;
  overflow: hidden;
  transition: scale 2s linear !important;
  cursor: pointer;
  -webkit-clip-path: inset(0% 0% 100% 0%);
          clip-path: inset(0% 0% 100% 0%);
}
.main-project .project__section-container .project__img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: inline-block;
}
.main-project .project__section-container .project__text-container {
  height: 0;
  color: white;
  font-size: 2.5rem;
}
@media (max-width: 700px) {
  .main-project .project__section-container .project__text-container {
    font-size: 6rem;
  }
}
.main-project .project__section-container .project__text-container h4 {
  font-family: "text-medium";
  font-weight: normal;
  margin-bottom: 0.5rem;
}
.main-project .project__section-container .project__text-container p {
  font-family: "text-regular";
  font-weight: 400;
}
@media (max-width: 700px) {
  .main-project .project__section-container .project__text-container p {
    font-size: 5.5rem;
    line-height: 1.1;
  }
}

.project-detail {
  width: 100%;
  padding: 18rem 0rem 9.6rem 0rem;
  color: white;
  background-color: rgb(0, 0, 0);
}
@media (max-width: 700px) {
  .project-detail {
    padding: 28rem 0rem 9.6rem 0rem;
  }
}
.project-detail .detail-title__container {
  font-family: "marco-medium";
  letter-spacing: 4px;
  font-weight: 500;
  font-size: 16rem;
  color: rgb(255, 246, 30);
  margin-bottom: 6rem;
  overflow-y: hidden;
  padding-left: 10rem;
}
@media (max-width: 700px) {
  .project-detail .detail-title__container {
    padding-left: 7rem;
    font-size: 18rem;
  }
}
.project-detail .detail-title__container h2 {
  line-height: 0.8;
  width: 100%;
  padding: 0 0 2rem 1rem;
}
.project-detail .detail-subtitle__container {
  padding-right: 10rem;
  overflow-y: hidden;
  margin-bottom: 6rem;
  padding-left: 10rem;
}
@media (max-width: 700px) {
  .project-detail .detail-subtitle__container {
    margin-bottom: 8rem;
    padding-left: 7rem;
  }
}
.project-detail .detail-subtitle__container span {
  font-family: "text-regular";
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.2;
  display: inline-block;
}
@media (max-width: 700px) {
  .project-detail .detail-subtitle__container span {
    font-size: 5.5rem;
    margin-bottom: 2rem;
  }
}
.project-detail .detail-subtitle__container h3 {
  font-family: "milgram-medium";
  font-weight: 500;
  font-size: 6rem;
  padding-bottom: 1.5rem;
  line-height: 0.9;
}
@media (max-width: 700px) {
  .project-detail .detail-subtitle__container h3 {
    font-size: 12rem;
  }
}
.project-detail .detail-minititle__container {
  overflow-y: hidden;
  margin-bottom: 6rem;
  padding-left: 10rem;
}
@media (max-width: 700px) {
  .project-detail .detail-minititle__container {
    padding-left: 7rem;
  }
}
.project-detail .detail-minititle__container span {
  font-family: "text-regular";
  font-weight: 400;
  font-size: 2.4rem;
  display: inline-block;
}
@media (max-width: 700px) {
  .project-detail .detail-minititle__container span {
    font-size: 5.5rem;
    margin-bottom: 2rem;
  }
}
.project-detail .detail-minititle__container h3 {
  font-family: "text-medium";
  font-weight: 500;
  font-size: 4rem;
  line-height: 1;
}
@media (max-width: 700px) {
  .project-detail .detail-minititle__container h3 {
    font-size: 8rem;
  }
}
.project-detail .detail-paragraph__container {
  position: relative;
  overflow-y: hidden;
  font-family: "text-regular";
  font-weight: 400;
  font-size: 2.4rem;
  padding-left: 10rem;
  padding-right: 10rem;
  line-height: 1.2;
  margin-bottom: 8rem;
}
@media (max-width: 700px) {
  .project-detail .detail-paragraph__container {
    margin-bottom: 12rem;
    font-size: 5.33rem;
    padding-left: 7rem;
  }
}
.project-detail .detail-img__container:first-child {
  display: none;
}
.project-detail .detail-img__container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding-left: 10rem;
  padding-right: 10rem;
  margin-bottom: 8rem;
  gap: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 12rem;
    gap: 12rem;
  }
}
.project-detail .detail-img__container .mark-down__pd-left {
  margin-right: auto;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-left {
    padding-left: 7rem !important;
    padding-right: 10rem !important;
    margin-left: unset;
  }
}
.project-detail .detail-img__container .mark-down__pd-center {
  margin: auto;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-center {
    padding-left: 7rem !important;
    padding-right: 10rem !important;
    margin-left: unset;
  }
}
.project-detail .detail-img__container .mark-down__pd-right {
  margin-left: auto;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-right {
    padding-left: 7rem !important;
    padding-right: 10rem !important;
    margin-left: unset;
  }
}
.project-detail .detail-img__container .mark-down__pd-left,
.project-detail .detail-img__container .mark-down__pd-center,
.project-detail .detail-img__container .mark-down__pd-right {
  width: 83.8rem;
}
.project-detail .detail-img__container .mark-down__pd-left p a,
.project-detail .detail-img__container .mark-down__pd-center p a,
.project-detail .detail-img__container .mark-down__pd-right p a {
  color: rgb(255, 246, 30);
  text-decoration: none;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-left,
  .project-detail .detail-img__container .mark-down__pd-center,
  .project-detail .detail-img__container .mark-down__pd-right {
    width: auto;
  }
}
.project-detail .detail-img__container .mark-down__pd-left blockquote p,
.project-detail .detail-img__container .mark-down__pd-center blockquote p,
.project-detail .detail-img__container .mark-down__pd-right blockquote p {
  font-family: "marco-medium";
  font-size: 6rem;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-left blockquote p,
  .project-detail .detail-img__container .mark-down__pd-center blockquote p,
  .project-detail .detail-img__container .mark-down__pd-right blockquote p {
    letter-spacing: 1.2rem;
    font-size: 11rem;
  }
}
.project-detail .detail-img__container .mark-down__pd-left h1,
.project-detail .detail-img__container .mark-down__pd-left h2,
.project-detail .detail-img__container .mark-down__pd-left h3,
.project-detail .detail-img__container .mark-down__pd-left h4,
.project-detail .detail-img__container .mark-down__pd-left h6,
.project-detail .detail-img__container .mark-down__pd-center h1,
.project-detail .detail-img__container .mark-down__pd-center h2,
.project-detail .detail-img__container .mark-down__pd-center h3,
.project-detail .detail-img__container .mark-down__pd-center h4,
.project-detail .detail-img__container .mark-down__pd-center h6,
.project-detail .detail-img__container .mark-down__pd-right h1,
.project-detail .detail-img__container .mark-down__pd-right h2,
.project-detail .detail-img__container .mark-down__pd-right h3,
.project-detail .detail-img__container .mark-down__pd-right h4,
.project-detail .detail-img__container .mark-down__pd-right h6 {
  font-family: "text-medium";
  font-size: 4rem;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-left h1,
  .project-detail .detail-img__container .mark-down__pd-left h2,
  .project-detail .detail-img__container .mark-down__pd-left h3,
  .project-detail .detail-img__container .mark-down__pd-left h4,
  .project-detail .detail-img__container .mark-down__pd-left h6,
  .project-detail .detail-img__container .mark-down__pd-center h1,
  .project-detail .detail-img__container .mark-down__pd-center h2,
  .project-detail .detail-img__container .mark-down__pd-center h3,
  .project-detail .detail-img__container .mark-down__pd-center h4,
  .project-detail .detail-img__container .mark-down__pd-center h6,
  .project-detail .detail-img__container .mark-down__pd-right h1,
  .project-detail .detail-img__container .mark-down__pd-right h2,
  .project-detail .detail-img__container .mark-down__pd-right h3,
  .project-detail .detail-img__container .mark-down__pd-right h4,
  .project-detail .detail-img__container .mark-down__pd-right h6 {
    font-size: 8rem;
    line-height: 1;
  }
}
.project-detail .detail-img__container .mark-down__pd-left h5,
.project-detail .detail-img__container .mark-down__pd-center h5,
.project-detail .detail-img__container .mark-down__pd-right h5 {
  font-size: 3rem;
  font-family: "text-medium";
  margin-bottom: 2.5rem;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-left h5,
  .project-detail .detail-img__container .mark-down__pd-center h5,
  .project-detail .detail-img__container .mark-down__pd-right h5 {
    font-size: 5rem;
    font-weight: bold;
  }
}
.project-detail .detail-img__container .mark-down__pd-left strong,
.project-detail .detail-img__container .mark-down__pd-center strong,
.project-detail .detail-img__container .mark-down__pd-right strong {
  font-family: "text-medium";
  font-size: 2.4rem;
  line-height: 0.9;
  display: inline-block;
  font-weight: bold;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-left strong,
  .project-detail .detail-img__container .mark-down__pd-center strong,
  .project-detail .detail-img__container .mark-down__pd-right strong {
    font-size: 5.33rem;
    display: inline-block;
  }
}
.project-detail .detail-img__container .mark-down__pd-left p,
.project-detail .detail-img__container .mark-down__pd-center p,
.project-detail .detail-img__container .mark-down__pd-right p {
  font-size: 2.4rem;
  font-family: "text-regular";
  line-height: 1.2;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .mark-down__pd-left p,
  .project-detail .detail-img__container .mark-down__pd-center p,
  .project-detail .detail-img__container .mark-down__pd-right p {
    font-size: 5.33rem;
  }
}
.project-detail .detail-img__container .detail-img__row {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: stretch;
  align-items: flex-start;
  gap: 4rem;
  width: 100%;
}
@media (max-width: 700px) {
  .project-detail .detail-img__container .detail-img__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 7rem;
    gap: 12rem;
  }
}
.project-detail .detail-img__container .detail-img__row img {
  width: calc(50% - 2rem);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.project-detail .detail-img__container .detail-img__row video {
  width: 100%;
  align-self: flex-start;
}
.project-detail .detail-img__container .block-hidden {
  overflow: hidden;
  flex: 1;
}
.project-detail .detail-img__container .block-hidden .center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: stretch;
}
.project-detail .detail-img__container .block-hidden .center img,
.project-detail .detail-img__container .block-hidden .center video {
  width: 100%;
  height: 100%;
}

.blog_page {
  background-color: rgb(0, 0, 0);
  padding: 18rem 0rem 10rem 0rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  gap: 8rem;
}
@media (max-width: 700px) {
  .blog_page {
    padding: 28rem 0rem 10rem 0rem;
  }
}
.blog_page .blog_category-container {
  padding-left: 12rem;
}
@media (max-width: 700px) {
  .blog_page .blog_category-container {
    padding-left: 7rem;
  }
}
@media (max-width: 700px) {
  .blog_page {
    gap: 15rem;
  }
}
.blog_page .blog_title-container {
  font-size: 16rem;
  color: rgb(255, 246, 30);
  font-family: "marco-medium";
  font-weight: 500;
  letter-spacing: 4px;
  padding-left: 12rem;
  padding-right: 12rem;
  overflow: hidden;
}
@media (max-width: 700px) {
  .blog_page .blog_title-container {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}
.blog_page .blog_title-container h3 {
  transform: translateY(100%);
}
@media (max-width: 700px) {
  .blog_page .blog_title-container {
    font-size: 20rem;
  }
}
.blog_page .blog_category-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  transform: translateY(150%);
  gap: 2rem;
  width: 100rem;
  flex-wrap: wrap;
}
@media (max-width: 700px) {
  .blog_page .blog_category-container {
    flex-wrap: nowrap;
    width: 100%;
  }
}
.blog_page .blog_category-container .blog_category-item.active::after {
  top: 0;
}
.blog_page .blog_category-container .active > span {
  color: rgb(107, 34, 255);
  z-index: 111;
}
.blog_page .blog_category-container .blog_category-item {
  color: rgb(93, 248, 20);
  background-color: rgb(107, 34, 255);
  font-size: 2.4rem;
  font-family: "text-medium";
  font-weight: 500;
  line-height: 1.2;
  padding: 2.4rem;
  border-radius: 4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
@media (max-width: 700px) {
  .blog_page .blog_category-container .blog_category-item {
    border-radius: 40px;
  }
}
.blog_page .blog_category-container .blog_category-item::after {
  content: "";
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 4rem;
  background-color: rgb(93, 248, 20);
  position: absolute;
  top: 100%;
  transition: top 0.3s ease-out;
}
@media (max-width: 700px) {
  .blog_page .blog_category-container .blog_category-item::after {
    border-radius: 5px;
  }
}
.blog_page .blog_category-container .blog_category-item:hover::after {
  top: 0;
}
.blog_page .blog_category-container .blog_category-item:hover > span {
  color: rgb(107, 34, 255);
  z-index: 111;
}
@media (max-width: 700px) {
  .blog_page .blog_category-container .blog_category-item {
    font-size: 5rem;
    padding: 4rem;
  }
}
.blog_page .blog-per-item {
  position: relative;
  padding-left: 12rem;
  padding-right: 12rem;
}
@media (max-width: 700px) {
  .blog_page .blog-per-item {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}
.blog_page .spacer-blog {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 11;
  transform: scaleY(1);
}
.blog_page .card-holder {
  padding-left: 1rem;
}
.blog_page .card-holder .card-column {
  justify-content: center;
  gap: 4rem;
}
@media (max-width: 700px) {
  .blog_page .card-holder .card-column {
    gap: 5rem;
  }
}
@media (max-width: 700px) {
  .blog_page .card-holder .card-middler {
    margin-bottom: 2rem;
  }
}
@media (max-width: 700px) {
  .blog_page .card-holder .card-img {
    margin-bottom: 8rem;
  }
}
.blog_page .card-holder .card-img img {
  -o-object-position: right;
     object-position: right;
  -o-object-fit: cover !important;
     object-fit: cover !important;
}
@media (max-width: 700px) {
  .blog_page .card-holder .card-img img {
    aspect-ratio: unset !important;
  }
}

.blog-detail {
  background-color: rgb(0, 0, 0);
  padding: 18rem 12rem 10.8rem 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  gap: 4rem;
}
@media (max-width: 700px) {
  .blog-detail {
    padding: 25rem 0rem 20rem 0rem;
  }
}
.blog-detail .card-topper {
  font-size: 2.4rem;
  font-family: "text-regular";
  font-weight: 400;
  color: rgb(0, 0, 0);
  gap: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
}
@media (max-width: 700px) {
  .blog-detail .card-topper {
    padding: 0rem 7rem 0rem 7rem;
    gap: 6rem;
  }
}
.blog-detail .card-topper .blog_date {
  font-size: 2.4rem;
  font-family: "text-regular";
  font-weight: 400;
  color: white;
}
@media (max-width: 700px) {
  .blog-detail .card-topper .blog_date {
    font-size: 5rem;
  }
}
@media (max-width: 700px) {
  .blog-detail .card-topper {
    font-size: 5rem;
  }
}
.blog-detail .blog-detail__title-wrapper {
  overflow-y: hidden;
  padding-left: 0.5rem;
  margin-bottom: 4rem;
}
@media (max-width: 700px) {
  .blog-detail .blog-detail__title-wrapper {
    padding: 0rem 7rem 0rem 7rem;
  }
}
.blog-detail .blog-detail__title-wrapper .hidden {
  overflow: hidden;
  padding: 0 0.5rem 2.5rem 0.8rem;
}
.blog-detail .blog-detail__title-wrapper .hidden:nth-child(1) {
  height: 18rem;
}
@media (max-width: 700px) {
  .blog-detail .blog-detail__title-wrapper .hidden:nth-child(1) {
    height: auto;
  }
}
.blog-detail .blog-detail__title-wrapper .hidden:nth-child(2) {
  transform: translateY(-4rem);
}
.blog-detail .blog-detail__title-wrapper .hidden:nth-child(3) {
  transform: translateY(-4rem);
}
.blog-detail .blog-detail__title-wrapper .hidden:nth-child(4) {
  transform: translateY(-4rem);
}
.blog-detail .blog-detail__title-wrapper .hidden:nth-child(5) {
  transform: translateY(-4rem);
}
.blog-detail .blog-detail__title-wrapper .hidden:nth-child(6) {
  transform: translateY(-4rem);
}
.blog-detail .blog-detail__title-wrapper .slide-blog-detail {
  font-size: 16rem;
  font-family: "marco-medium";
  font-weight: 500;
  color: rgb(255, 246, 30);
  line-height: 0.78;
  display: inline-block;
  transform: translateY(110%);
}
@media (max-width: 700px) {
  .blog-detail .blog-detail__title-wrapper .slide-blog-detail {
    font-size: 18rem;
    margin-bottom: 2rem;
  }
}
.blog-detail .blog-detail__title-wrapper .slide-blog-detail span {
  display: block;
}
.blog-detail .card-im {
  width: 100%;
  overflow: hidden;
  margin-bottom: 4rem;
}
.blog-detail .card-im img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.blog-detail .markdowns-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  padding-right: 24rem;
}
@media (max-width: 700px) {
  .blog-detail .markdowns-wrapper {
    padding: 0rem 7rem 0rem 7rem;
  }
}
.blog-detail .blog__markdown-one-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25% !important;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 2rem;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog_category-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
    width: 100% !important;
    margin-bottom: 10rem;
    margin-top: 4rem;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .share-s-color {
  background-color: rgb(93, 248, 20) !important;
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a {
  color: rgb(93, 248, 20);
  background-color: rgb(107, 34, 255);
  font-size: 2.4rem;
  font-family: "text-medium";
  padding: 2.4rem;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a span {
  display: inline-block;
  height: 2.4rem;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a span {
    height: auto;
  }
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a {
    font-size: 5rem;
    padding: 4rem;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a ul li {
  list-style: none;
  align-self: center;
  display: inline-block;
  margin: auto;
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a ul li a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a ul li a svg {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-a ul li a svg {
    width: 5.5rem;
    height: 5.5rem;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-b {
  color: rgb(93, 248, 20);
  background-color: rgb(107, 34, 255);
  font-size: 2.4rem;
  font-family: "text-medium";
  padding: 2.4rem;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-b {
    font-size: 5rem;
    padding: 4rem;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-b::after {
  content: "";
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
  background-color: rgb(93, 248, 20);
  position: absolute;
  top: 100%;
  transition: top 0.3s ease-out;
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-b:hover::after {
  top: 0;
}
.blog-detail .blog__markdown-one-wrapper .blog_category-container .blog_category-item-b:hover > span {
  color: rgb(107, 34, 255);
  z-index: 111;
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one {
  font-family: "text-medium";
  width: 100%;
  line-height: 1;
  color: white;
  font-size: 2.4rem;
  display: block;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one {
    font-size: 5.33rem;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one blockquote p {
  font-family: "marco-medium";
  font-size: 6rem;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0.5rem;
  margin-bottom: 0rem;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one blockquote p {
    letter-spacing: 1.2rem;
    font-size: 11rem;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one video {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 5rem;
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one video::-webkit-media-controls-start-playback-button {
  display: none;
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one video::-webkit-media-controls {
  display: none !important;
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one p a {
  color: rgb(255, 246, 30);
  text-decoration: none;
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one strong {
  font-family: "text-medium";
  font-size: 2.4rem;
  line-height: 0.9;
  font-weight: bold;
  display: inline-block;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one strong {
    font-size: 5.33rem;
    display: inline-block;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one ol,
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one ul {
  margin-left: 2.8rem;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one ol,
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one ul {
    margin-left: 4.4rem;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one h1,
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one h2,
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one h3,
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one h4,
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one h6 {
  font-family: "text-medium";
  font-size: 4rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one h1,
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one h2,
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one h3,
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one h4,
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one h6 {
    font-size: 8rem;
    line-height: 1;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one p,
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one li {
  margin: 0 0 6.4rem 0;
  font-family: "text-regular";
  line-height: 1.2;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one p,
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one li {
    margin: 0 0 10rem 0;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one h5 {
  font-size: 3rem;
  font-family: "text-medium";
  margin-bottom: 2.5rem;
}
@media (max-width: 700px) {
  .blog-detail .blog__markdown-one-wrapper .blog__markdown-one h5 {
    font-size: 5rem;
    font-weight: bold;
  }
}
.blog-detail .blog__markdown-one-wrapper .blog__markdown-one img {
  width: 100%;
}
.blog-detail .blog__markdown-two-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 23rem;
}
.blog-detail .blog__markdown-two-wrapper .blog__markdown-two {
  font-family: "text-regular";
  line-height: 1;
  color: white;
  font-size: 2.4rem;
}
.blog-detail .blog__markdown-three-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 23rem;
}
.blog-detail .blog__markdown-three-wrapper .blog__markdown-three {
  font-family: "text-regular";
  color: white;
  font-size: 8rem;
  line-height: 0.8;
}
.blog-detail .blog-img__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 23rem;
}
.blog-detail .blog-img__wrapper img {
  width: 100%;
}

.team {
  padding: 12rem 12rem 0rem 12rem;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
@media (max-width: 700px) {
  .team {
    padding: 20rem 7rem 0rem 7rem;
  }
}
.team .team__title-container {
  overflow-y: hidden;
  margin-bottom: 10rem;
  align-self: flex-start;
  position: sticky;
  top: 50%;
  margin-top: 20rem;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 700px) {
  .team .team__title-container {
    position: static;
    top: 0;
    margin-top: 0rem;
    transform: translateY(0%);
    margin-bottom: 10rem;
  }
}
.team .team__title-container span {
  position: relative;
  font-size: 6rem;
  z-index: 11;
  font-family: "marco-medium";
  font-weight: 400;
  letter-spacing: 3px;
  color: rgb(255, 246, 30);
}
@media (max-width: 700px) {
  .team .team__title-container span {
    font-size: 10rem;
  }
}
.team h3 {
  font-size: 30rem;
  color: rgb(255, 246, 30);
  font-family: "marco-medium";
  font-weight: 500;
  text-align: center;
}
@media (max-width: 700px) {
  .team h3 {
    font-size: 37rem;
  }
}
.team .spacer-t {
  height: 101%;
  width: 101%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 11;
  bottom: 0;
}
.team .team-collection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  align-self: flex-start;
  width: 100%;
  height: 100%;
}
@media (max-width: 700px) {
  .team .team-collection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.team .team__section-container {
  width: calc(40% - 4rem);
  margin-bottom: 12rem;
}
@media (max-width: 700px) {
  .team .team__section-container {
    width: 100%;
  }
}
.team .team__section-container .add-space {
  height: 50rem;
}
.team .team__section-container .team__img-container {
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
  overflow: hidden;
  transition: scale 2s linear !important;
}
@media (max-width: 700px) {
  .team .team__section-container .team__img-container {
    margin-bottom: 5rem;
  }
}
.team .team__section-container .team__img-container img {
  width: 100%;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  display: inline-block;
}
.team .team__section-container .team__text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  font-size: 2.5rem;
}
@media (max-width: 700px) {
  .team .team__section-container .team__text-container {
    font-size: 6rem;
  }
}
.team .team__section-container .team__text-container h4 {
  font-family: "text-medium";
  font-weight: normal;
  margin-bottom: 0.5rem;
}
.team .team__section-container .team__text-container p {
  font-family: "text-regular";
  font-weight: 400;
}
@media (max-width: 700px) {
  .team .team__section-container .team__text-container p {
    font-size: 5.5rem;
    line-height: 1;
    width: 48rem;
  }
}
.team .team__section-container .team__text-container .team__profile-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
}
@media (max-width: 700px) {
  .team .team__section-container .team__text-container .team__profile-wrapper {
    gap: 2rem;
  }
}
.team .team__section-container .team__text-container .team__profile-wrapper h4 {
  font-family: "text-regular";
  font-weight: normal;
  margin-bottom: 0rem;
  font-size: 2.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 700px) {
  .team .team__section-container .team__text-container .team__profile-wrapper h4 {
    font-size: 5rem;
  }
}
.team .team__section-container .team__text-container .team__profile-wrapper img {
  vertical-align: middle;
  width: 3rem;
  aspect-ratio: 1;
}
@media (max-width: 700px) {
  .team .team__section-container .team__text-container .team__profile-wrapper img {
    width: 5rem;
  }
}
.team .team__section-container:nth-child(2) {
  transform: translateY(40rem);
}
@media (max-width: 700px) {
  .team .team__section-container:nth-child(2) {
    transform: unset;
  }
}

.portfolio {
  padding: 12rem 12rem 16.8rem 12rem;
  background-color: rgb(0, 0, 0);
  position: relative;
}
@media (max-width: 700px) {
  .portfolio {
    padding: 0rem 7rem 64px 7rem;
  }
}
.portfolio .portfolio__first-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
@media (max-width: 700px) {
  .portfolio .portfolio__first-block {
    justify-content: center;
    padding: 0rem 7rem 16.8rem 7rem;
  }
}
.portfolio .portfolio__first-block .portfolio__fkt-wrapper img {
  width: 125rem;
  aspect-ratio: 1.3;
}
.portfolio .portfolio__first-block .portfolio__git-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 7rem;
  bottom: -3rem;
  right: 0;
  z-index: 11;
}
@media (max-width: 700px) {
  .portfolio .portfolio__first-block .portfolio__git-wrapper {
    right: 50%;
    top: 68rem;
    bottom: unset;
    transform: translateX(50%);
    aspect-ratio: 240/240;
    width: 80.29%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.portfolio .portfolio__first-block .portfolio__git-wrapper .guy {
  width: 60rem;
  aspect-ratio: 1;
}
@media (max-width: 700px) {
  .portfolio .portfolio__first-block .portfolio__git-wrapper .guy {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.portfolio .portfolio__second-block {
  align-self: flex-start;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 700px) {
  .portfolio .portfolio__second-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
.portfolio .portfolio__second-block .portolio__paragraph-wrapper {
  font-family: "text-regular";
  font-weight: 400;
  font-size: 2.4rem;
  width: 70rem;
}
@media (max-width: 700px) {
  .portfolio .portfolio__second-block .portolio__paragraph-wrapper {
    font-size: 5.5rem;
    width: 100%;
    margin-top: 62rem;
  }
}
.portfolio .portfolio__second-block .portfolio__right-container {
  color: white;
  align-self: flex-start;
  margin-top: 10rem;
}
@media (max-width: 700px) {
  .portfolio .portfolio__second-block .portfolio__right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(0rem);
    margin-right: 0rem;
    width: 100%;
  }
}
.portfolio .portfolio__second-block .portfolio__right-container .slide-wrapper {
  font: 500 4rem "marco-medium";
  width: 62.5rem;
  text-align: left;
  transform: translateX(2rem);
  margin-bottom: 2.4rem;
}
.portfolio .portfolio__second-block .portfolio__right-container .slide-wrapper .hidden {
  overflow: hidden;
}
.portfolio .portfolio__second-block .portfolio__right-container .slide-wrapper span {
  letter-spacing: 0.4rem;
  display: inline-block;
  padding-left: 1rem;
}
@media (max-width: 700px) {
  .portfolio .portfolio__second-block .portfolio__right-container .slide-wrapper {
    font-size: 8rem;
    width: 100%;
    margin-bottom: 5rem;
  }
}
.portfolio .portfolio__second-block .portfolio__right-container .btn-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  padding-left: 2.6rem;
  width: 100%;
}
@media (max-width: 700px) {
  .portfolio .portfolio__second-block .portfolio__right-container .btn-wrapper {
    justify-content: stretch;
  }
}
.portfolio .portfolio__second-block .portfolio__right-container .btn-wrapper .btn-text-wrapper {
  font: 500 4rem "text-medium";
  color: "white";
}
@media (max-width: 700px) {
  .portfolio .portfolio__second-block .portfolio__right-container .btn-wrapper .btn-text-wrapper {
    font-size: 8rem;
    width: 100%;
  }
}
.portfolio .portfolio__second-block .portfolio__right-container .btn-wrapper .btn-container {
  transform: rotate(90deg);
}

.spacer-portfolio {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(0, 0, 0);
  z-index: 11;
  bottom: 0;
}

.policy-privacy {
  width: 100%;
  background-color: rgb(0, 0, 0);
  padding: 16.8rem 12rem 2rem 12rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 700px) {
  .policy-privacy {
    padding-left: 7rem;
    padding-right: 7rem;
    padding: 32.8rem 12rem 12rem 12rem;
  }
}
.policy-privacy .markdown-policy-privacy h1,
.policy-privacy .markdown-policy-privacy h2,
.policy-privacy .markdown-policy-privacy h3,
.policy-privacy .markdown-policy-privacy h4,
.policy-privacy .markdown-policy-privacy h5,
.policy-privacy .markdown-policy-privacy h6 {
  font-family: "text-medium";
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 2.51rem;
  display: inline-block;
}
@media (max-width: 700px) {
  .policy-privacy .markdown-policy-privacy h1,
  .policy-privacy .markdown-policy-privacy h2,
  .policy-privacy .markdown-policy-privacy h3,
  .policy-privacy .markdown-policy-privacy h4,
  .policy-privacy .markdown-policy-privacy h5,
  .policy-privacy .markdown-policy-privacy h6 {
    font-size: 8rem;
    display: inline-block;
  }
}
.policy-privacy .markdown-policy-privacy blockquote P {
  font-family: "text-medium";
  font-size: 6rem;
  line-height: 1.2;
}
.policy-privacy .markdown-policy-privacy P strong {
  font-family: "text-medium";
  font-size: 4rem;
  line-height: 0.9;
  display: inline-block;
}
@media (max-width: 700px) {
  .policy-privacy .markdown-policy-privacy P strong {
    font-size: 5rem;
    display: inline-block;
  }
}
.policy-privacy .markdown-policy-privacy p {
  font-size: 2.4rem;
  font-family: "text-regular";
  margin-bottom: 8rem;
  line-height: 1.2;
}
@media (max-width: 700px) {
  .policy-privacy .markdown-policy-privacy p {
    font-size: 5rem;
    margin-bottom: 12rem;
  }
}/*# sourceMappingURL=main.css.map */