.footer {
  @include flex-center(column);
  padding: 6.4rem 6.4rem 0rem 6.4rem;
  gap: 16.8rem;
  background-color: $yalloo-primary-500;
  position: relative;

  &__upper {
    @include flex-center(row);
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    @media (max-width: 700px) {
      align-items: flex-start;
    }
    .upper-left-container {
      @include flex-center(row);
      align-items: flex-end;
      gap: 8rem;
      @media (max-width: 700px) {
        @include flex-center(column);
        align-items: flex-start;
      }
    }
    .policy {
      @include flex-center(column);
      align-items: flex-end;
      gap: 0.7rem;
      @media (max-width: 700px) {
        align-items: flex-start;
        font-size: 6rem;
        gap: 4rem;
      }
      a {
        @include text-decoration-none;
        @include footer-policy-style;
        font-family: $text-regular;
        font-weight: 400;
        position: relative;
        padding-bottom: 0.57rem;
        display: block;

        line-height: 1;

        position: relative;
        @media (max-width: 700px) {
          font-size: 6rem;
          padding-bottom: 0.7rem;
        }
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          bottom: 0;
          @media (max-width: 700px) {
            top: 6.5rem;
          }
          background-color: black;
          transform: scaleX(0);
          -webkit-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s;
          transform-origin: left;
        }

        &:hover {
          &::before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            transform: scaleY(0.7);
          }
        }
      }
      .company-number {
        text-align: right;

        @media (max-width: 700px) {
          text-align: left;

          font-size: 5rem;
          line-height: 1.3;
          @media (max-width: 700px) {
            font-size: 6rem;
          }
        }
        @include footer-policy-style;
        p {
          font-family: $text-regular;
        }
        h3 {
          padding-top: 4rem;
          font-weight: normal;
          font-family: $text-regular;
        }
      }
    }
  }
  &__menu-list {
    font-size: $size-6;

    li {
      @include list-style-none;
      line-height: 1;
      cursor: pointer;
      height: min-content;
      overflow: hidden;

      &:hover {
        .second-item {
          transform: translateY(0);
        }

        .second-item a {
          transform: translateY(0);
        }
      }

      .second-item {
        overflow: hidden;
        transform: translateY(100%);
        transition: transform 0.5s ease;
        letter-spacing: 0.2rem;
        font-family: $marco-medium;

        a {
          display: inline-block;
          transform: translateY(-100%);

          @include text-decoration-none;
          transition: transform 0.5s ease;
          color: $purpal-secondary-500;
          padding: 0px 1rem;
          @media (max-width: 700px) {
            font-size: 11rem;
            letter-spacing: 0.9rem;
          }
        }
      }
      .first-item {
        position: absolute;
        font-family: $marco-medium;
        letter-spacing: 0.2rem;
        @media (max-width: 700px) {
          font-size: 11rem;
          letter-spacing: 0.9rem;
        }
        p {
          padding: 0px 1rem;
        }
      }
    }
  }

  .fotonika-logo-container {
    width: 100%;
    height: 100%;
    margin-bottom: 8rem;
  }
}

@keyframes color-up {
  0% {
  }
  100% {
    transform: translateY(0%);
  }
}
