.contact {
  background-color: $black-color;
  padding: 16.8rem 12rem 16.8rem 12rem;
  position: relative;
  z-index: 111;
  @include flex-center(row);
  justify-content: stretch;
  @media (max-width: 700px) {
    @include flex-center(column);
    padding: 64px 7rem 20rem 7rem;
    gap: 8rem;
  }

  &__first-wrapper {
    @include flex-center(column);
    align-items: flex-start;
    margin-right: auto;
    gap: 5.6rem;
    flex-basis: 65%;
    @media (max-width: 700px) {
      gap: 8rem;
      width: 100%;
    }

    .question-container {
      @include over-flow;
      .question {
        font-size: $size-16;
        color: $yalloo-primary-500;
        font-family: $marco-medium;
        font-weight: 500;
        line-height: 0.75;
        span {
          padding-bottom: 2.1rem;
        }

        @media (max-width: 700px) {
          font-size: 18rem;
        }
      }
    }

    p {
      @include contactTextStyle;
      font-family: $text-medium;
      font-weight: 500;

      @media (max-width: 700px) {
        font-size: 8rem;
      }
    }
    .inner-container {
      @include over-flow;
      align-self: flex-start;
      @include flex-center(row);
      @include inner-container;
      cursor: pointer;

      @media (max-width: 700px) {
        font-size: 5.5rem;
      }
      .small-white-star {
        @include infiniteRotate(2s);
        width: 2rem;
        align-self: flex-start;
        margin-top: 2.7rem;
        margin-left: 0.8rem;
        @media (max-width: 700px) {
          width: 3rem;
        }
      }
    }
  }
  &__second-wrapper {
    @include flex-center(column);
    height: 100%;
    width: 40rem;
    gap: 4rem;
    align-items: flex-end;

    @media (max-width: 700px) {
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }

    .spacer-contact {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $black-color;
      z-index: 111;
      transform-origin: bottom;
    }

    .cat-img {
      width: 70%;
      @media (max-width: 700px) {
        display: none;
      }
    }

    p {
      @include contactTextStyle;
      align-self: flex-end;
      text-align: right;
      font-weight: 500;

      font-family: $text-medium;
      display: inline-block !important;
      width: 51%;
      line-height: 0.9;
      padding-bottom: 0.1rem;
      @media (max-width: 700px) {
        text-align: left;
      }
    }

    .inner-container {
      @include inner-container;
      @include over-flow;
      justify-content: stretch;
      height: 7.5rem;

      @media (max-width: 700px) {
        justify-content: flex-end;
        margin-right: auto;
        height: auto;
      }
      span {
        @media (max-width: 700px) {
          font-size: 8rem;
        }
      }
      img {
        @include infiniteRotate(2s);
        width: 2rem;
        margin-right: 0.8rem;

        @media (max-width: 700px) {
          width: 3rem;
        }
      }

      .flex-start {
        align-self: flex-start;
      }
    }
  }
}
