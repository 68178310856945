.blog {
  padding: 16.8rem 25rem 20rem 25rem;
  background-color: $black-color;
  z-index: 111;
  height: 290rem;
  will-change: height;

  position: relative;
  @media (max-width: 700px) {
    padding: 16.8rem 10rem 210rem 10rem;
    height: 600rem;
  }
  .blog-holder {
    position: sticky;
    top: 0;
  }

  padding-bottom: 50rem;
  h3 {
    font-size: 50rem;
    font-family: $marco-medium;
    font-weight: 500;
    color: $yalloo-primary-500;
    text-align: center;
    position: relative;
    margin-bottom: 10rem;
    position: sticky;
    top: 0;
  }
  .yellow {
    background-color: $yalloo-primary-500;
    position: absolute;
    top: 50%;
  }
  .purple {
    background-color: $purpal-secondary-500;
    position: absolute;
    top: 50%;

    color: $white-color !important;
    .card-topper {
      color: $white-color !important;
    }
    .card-bottomer {
      color: $white-color !important;
    }
  }
  .green {
    background-color: $white-color;
    position: absolute;
    top: 50%;
  }
}

.blog__btn-wrapper {
  width: 100%;
  padding-top: 2rem;
  background-color: $black-color;
  z-index: 99;
  position: relative;
  @include flex-center(column);
  @media (max-width: 700px) {
    padding-top: 10rem;
    padding-bottom: 20rem;
  }
  cursor: pointer;

  :hover {
    #path {
      @include lineAnimate;
    }
  }
  .blog__text-svg-wrapper {
    position: relative;

    width: 35rem;
    @media (max-width: 700px) {
      width: 50rem;
    }
  }

  .blog__svg-btn {
    position: absolute;
    height: 100%;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  span {
    color: $white-color;
    font-family: $text-medium;
    font-weight: 500;
    font-size: $size-4;
    text-align: center;
    display: inline-block;
    line-height: 0.9;

    @media (max-width: 700px) {
      font-size: 8rem;
    }
  }
}
