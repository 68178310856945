.main-section {
  @include flex-center(column);
  background-color: $yalloo-primary-500;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 30rem;

  position: relative;
  @media (max-width: 700px) {
    padding-bottom: 5%;
    padding-left: 3rem;
    padding-right: 3rem;
    will-change: transform;
    transform: translateY(-5%);
  }

  &__level0-container {
    @include flex-center(row);
    justify-content: flex-start;
    width: 100%;
    gap: 10rem;

    margin-bottom: 4rem;
    @include over-flow;

    @media (max-width: 700px) {
      gap: 0px;
      will-change: transform;
    }
    .model-container {
      height: 60rem;
      width: 100rem;
      top: 5%;
      left: 33%;
      z-index: 99 !important;

      position: absolute;

      @media (max-width: 700px) {
        height: 115rem;
        width: 100%;

        left: -1%;
      }
    }

    canvas {
      will-change: transform;

      @media (max-width: 700px) {
        position: relative !important;
      }
    }
    @media (max-width: 700px) {
      @include flex-center(column);
      flex-direction: column-reverse;
      justify-content: space-around;
      gap: 0;
      margin-bottom: 0px;
    }
    .spacer {
      width: 100%;

      position: relative;

      @media (max-width: 700px) {
        height: 120rem;
      }
    }
    p {
      font-size: 8rem;
      line-height: 0.8;
      font-weight: 500;
      padding-bottom: 2rem;
      will-change: transform;
      font-family: $marco-medium;
      @include paddingFix(left);

      letter-spacing: 4px;
      width: 70rem;
      @media (max-width: 700px) {
        font-size: 12rem;
        letter-spacing: 3px;
        width: 120rem;
        text-align: left;
        flex-basis: 2.5em;
      }
      @media (max-width: 430px) {
        flex-basis: 3em;
      }
    }
  }

  &__level1-container {
    @include flex-center(row);
    justify-content: space-between;
    transform: translateY(4rem);
    will-change: transform;
    @media (max-width: 700px) {
      transform: translateY(6rem);
    }

    overflow: hidden;

    width: 100%;
    P {
      line-height: 1;
      font-family: $marco-medium;
      font-weight: 500;

      .zero {
        @include bigFontStyle($purpal-secondary-500);

        @include paddingFix(left);
        font-size: 24rem;
        @include paddingFix(right, 6rem);
        @media (max-width: 700px) {
          font-size: 22rem;
        }
      }
      &.fuffa {
        @include bigFontStyle("");
        font-size: 24rem;
        font-weight: 500;

        @include paddingFix(top, 5px);
        @media (max-width: 700px) {
          font-size: 22rem;
        }
      }
    }
  }
  .level2-main-container {
    position: relative;
    align-self: flex-start;

    .star-container {
      position: absolute;
      width: 9rem;
      height: 9rem;
      right: -1.5rem;
      top: 0;
      display: flex;

      justify-content: center;
      align-items: center;
      @media (max-width: 700px) {
        top: 60%;
        right: unset;
        left: 28%;
      }
    }
    .loading-star {
      position: absolute;
      top: 0rem;
      align-self: center;
      @media (max-width: 700px) {
        will-change: transform;
      }
      @include flex-center(row);

      height: 100%;
      width: 100%;
      @media (max-width: 700px) {
        top: -4rem;
      }

      svg {
        width: 8rem;
        height: 8rem;
        aspect-ratio: 1;
        @include infiniteRotate(2s);
        animation-delay: 4s;
        transform-origin: center;
      }
    }

    .loading-star--t {
      position: absolute;
      top: 0rem;
      width: 100%;
      height: 100%;
      @media (max-width: 700px) {
        top: -4rem;
      }

      svg {
        width: 8rem;
        height: 8rem;
        aspect-ratio: 1;
        @include infiniteRotate(2s);

        transform-origin: center;
      }
    }
  }

  &__level2-container {
    @include flex-center(row);
    @include paddingFix(left, 5px);
    transform: translateY(-8%);
    justify-content: flex-start;
    gap: 3rem;
    width: 100%;
    will-change: transform;
    padding-bottom: 5rem;
    overflow: hidden;
    @media (max-width: 700px) {
      padding-bottom: 0;
      transform: translateY(1rem);
    }

    p {
      line-height: 1;
      will-change: transform;
      font-family: $marco-medium;
      font-weight: 500;

      &.pro {
        @include bigFontStyle("");
        font-size: 24rem;
        will-change: transform;
        line-height: 0.25;
        padding-bottom: 13rem;
        padding-right: 1rem;
        margin-right: 9rem;
        font-weight: 500;

        display: inline-block;
        @media (max-width: 700px) {
          line-height: 0.6 !important;
          padding-bottom: 7rem;
          margin-right: unset;
          padding-right: unset;
        }

        .hunderd {
          @include bigFontStyle($purpal-secondary-500);
          padding-right: 10px;
          font-size: 24rem;
          display: inline-block;
          will-change: transform;
          line-height: 1.2;
          font-weight: 500;
        }
        @media (max-width: 700px) {
          font-size: 22rem;
          line-height: 1.2;
          .hunderd {
            @media (max-width: 700px) {
              font-size: 22rem;
              line-height: 0.9;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }

  &__nested-container {
    @include flex-center(row);
    justify-content: flex-end;
    width: 100%;
    @include over-flow;
    align-self: flex-start;
    position: relative;
    will-change: transform;
    gap: 1rem;

    img {
      &.small-star {
        align-self: flex-start;
        display: inline-block;
        text-align: right;
        width: 3rem;
        height: 3rem;
        will-change: transform;

        @media (max-width: 700px) {
          width: 5rem;
          height: 5rem;
        }
      }
      &.rotate {
        @include infiniteRotate(2s);
      }
    }
    p {
      will-change: transform;
      &.text {
        font-size: $size-6;
        @media (max-width: 700px) {
          font-size: 10rem !important;
          line-height: 9rem;
        }
        color: $purpal-secondary-500;
        text-align: left;
        font-family: $marco-medium;
        font-weight: 500;
        letter-spacing: 0.7rem;
        line-height: 5.5rem;
        align-self: center;
        display: inline-block;
        width: min-content;
        will-change: transform;
      }
    }
  }
}
