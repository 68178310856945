.project {
  background-color: $yalloo-primary-500;
  position: relative;
  padding: 10rem 0 25rem 0;
  position: relative;
  z-index: 99;
  @media (max-width: 700px) {
    transform: translateY(-25rem);
    padding: 0rem 0 0rem 0;
  }
  color: $yalloo-primary-500;

  @include flex-center(column);
  justify-content: flex-start;
  height: 100%;

  .text-container {
    @include flex-center(column);
    position: sticky;
    top: 25%;
    @media (max-width: 700px) {
      top: 50%;
      position: static;
    }
    .spacer-text {
      height: 100%;
      width: 101%;
      position: absolute;
      background-color: $black-color;
      z-index: 1111;
      transform-origin: bottom;
    }
  }

  &-title {
    position: relative;
    font-size: 30rem;
    z-index: 11;
    font-family: $marco-medium;
    font-weight: 500;
    text-align: center;

    @media (max-width: 700px) {
      line-height: 0.8;
      font-size: 37rem;
    }
    margin-bottom: 5rem;
  }
  &-mini-text {
    position: relative;
    font-size: $size-6;
    z-index: 11;
    font-family: $marco-medium;
    font-weight: 400;
    letter-spacing: 3px;
    @media (max-width: 700px) {
      font-size: 10rem;
    }
  }

  .star-container {
    position: absolute;
    bottom: 80%;
    transform: scale(0);
    transform-origin: 0 0;
    will-change: scale;
  }

  .main-block-two {
    @include flex-center(row);
    align-items: flex-start;
    justify-content: space-between;
    visibility: hidden;
    width: 100%;
    padding: 0 12rem 20rem 12rem;

    @media (max-width: 700px) {
      @include flex-center(column);
      position: relative;
      padding: 0rem 12rem 0rem 12rem;
    }
    .second-img-container {
      align-self: center;
      flex: 0.3;
      overflow: hidden;
      .img-container {
        .spacer {
          height: 100%;
          width: 100%;
          position: absolute;
          background-color: $black-color;
          z-index: 11;

          transform-origin: bottom;
        }
        align-self: center;
        transform-origin: top;
        position: relative;
        z-index: 12;
        width: 100%;
        margin-left: auto;
        will-change: transform;

        will-change: transform;
        aspect-ratio: 1;
        overflow: hidden;
      }
      a {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          scale: 1.1 !important;
        }
      }
    }
    .first-img-container {
      flex: 0.4;
      .img-container {
        .spacer {
          height: 100%;
          width: 100%;
          position: absolute;
          background-color: $black-color;
          z-index: 11;
          transform-origin: bottom;
        }
        position: relative;
        z-index: 12;
        width: 100%;
        will-change: transform;
        aspect-ratio: 1;
        overflow: hidden;
        will-change: transform;
        @media (max-width: 700px) {
        }
      }

      a {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          scale: 1.1 !important;
          object-fit: cover;
        }
      }
    }
  }

  .text-cover {
    position: relative;
    font-family: $text-regular;
    font-weight: 500;
    line-height: 1.2;
    .spacer-title {
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: $black-color;
      z-index: 99999;

      transform-origin: top;
    }
  }
  .main-block {
    @include flex-center(row);
    visibility: hidden;
    width: 100%;

    overflow: hidden;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0rem 12rem 20rem 12rem;
    @media (max-width: 700px) {
      @include flex-center(column);
      position: relative;
      padding: 10rem 12rem 0rem 12rem;
    }

    .first-img-container {
      flex: 0.3;

      @include flex-center(column);
      align-items: flex-start;
      .img-container {
        .spacer {
          height: 100%;
          width: 100%;
          position: absolute;
          background-color: $black-color;
          z-index: 11;
          transform-origin: bottom;
        }
        position: relative;
        z-index: 12;
        width: 100%;
        will-change: transform;
        aspect-ratio: 1;
        overflow: hidden;
      }
      a {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          scale: 1.1 !important;
        }
      }
    }
    .second-img-container {
      flex: 0.4;
      @include flex-center(column);
      align-items: flex-end;
      @media (max-width: 700px) {
        align-items: flex-start;
      }

      .img-container {
        .spacer {
          height: 100%;
          width: 100%;
          position: absolute;
          background-color: $black-color;
          z-index: 11;
          transform-origin: bottom;
        }
        position: relative;
        z-index: 12;
        width: 100%;
        will-change: transform;
        aspect-ratio: 1;
        overflow: hidden;
        will-change: transform;
      }
      a {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          scale: 1.1 !important;
          object-fit: cover;
        }
      }
    }
  }

  .project__btn {
    @include flex-center(row);
    color: $white-color;
    z-index: 111;
    font-family: $text-medium;
    font-weight: 500;
    font-size: $size-4;
    @media (max-width: 700px) {
      font-size: 8.5rem;
      margin-bottom: 12rem;
    }
    transform: translateY(15rem);
    cursor: pointer;
    span {
      display: inline-block;
      width: 25rem;
      @media (max-width: 700px) {
        width: 40%;
      }
    }
  }
}

.parall-title {
  position: relative;
  font-family: $text-medium;
  font-weight: 500;
  font-size: 2.4rem;
  color: $white-color;
  margin-top: 3rem;
  margin-bottom: 0.5rem;

  z-index: 1111;
  @media (max-width: 700px) {
    margin-top: 5rem;
    font-size: 6rem;
  }
}

.parall-text {
  position: relative;
  font-family: $text-regular;
  font-size: 2.4rem;
  color: $white-color;
  z-index: 1111;
  line-height: 1.2;
  font-weight: normal;
  @media (max-width: 700px) {
    font-size: 5.5rem;
    margin-bottom: 10rem;
  }
}
