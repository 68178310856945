.social-media {
  font-size: $size-2-5;
  font-family: $text-bold;
  text-transform: uppercase;
  @include list-style-none;

  @media (max-width: 650px) {
    line-height: 1.2;
  }

  li {
    text-align: right;
    font-family: $text-medium;
    font-weight: 500;
    padding-bottom: 0.8rem;
    height: 100%;
    &:last-child {
      padding-bottom: 0;
    }

    cursor: pointer;
    @media (max-width: 650px) {
      text-align: left;
    }
    a {
      @include text-decoration-none;
      color: $black-color;

      height: 100%;

      position: relative;
      @media (max-width: 650px) {
        font-size: 6rem;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: 2.8rem;
        @media (max-width: 700px) {
          top: 6.5rem;
        }
        background-color: black;
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        transform-origin: left;
      }

      &:hover {
        &::after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          transform: scaleY(0.8);
        }
      }
    }
  }
}
