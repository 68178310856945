.form-container {
  background-color: $black-color;
  padding: 16.8rem 12rem 0px 12rem;
  @media (max-width: 700px) {
    padding: 16.8rem 7rem 0px 7rem;
  }
  .form-title-container {
    overflow: hidden;
    margin-bottom: 6rem;
    @media (max-width: 700px) {
      margin-bottom: 10rem;
    }
  }
  .form-title {
    font-size: $size-6;
    font-family: $text-medium;
    font-weight: 500;
    color: $white-color;
    text-align: left;
    line-height: 1;
    padding-bottom: 1rem;
    @media (max-width: 700px) {
      font-size: 10rem;
    }
  }
  .form {
    @include flex-center(column);
    gap: $size-2-5;
    width: 100%;
    margin-bottom: 6rem;
  }
  .btn-container {
    @include flex-center(row);
    justify-content: flex-end;
    width: 100%;
    @media (max-width: 500px) {
      justify-content: flex-start;
      margin-left: unset;
    }
    span {
      color: $white-color;
      font-size: $size-4;
      font-family: $text-medium;
      font-weight: 500;
      @media (max-width: 700px) {
        font-size: 7rem;
      }
    }
  }
  .bottom-container {
    @include flex-center(row);
    justify-content: flex-start;
    margin-top: 5rem;
    @media (max-width: 700px) {
      @include flex-center(column);
      justify-content: flex-start;
      align-items: flex-start;
      gap: 6rem;
      width: 100%;
      margin-top: 10rem;
    }

    .privacy-container {
      @include flex-center(row);
      justify-content: flex-start;
      width: 100%;

      p {
        font-size: 2.4rem;
        font-family: $text-regular;
        line-height: 1.2;
        font-weight: 400;
        color: $white-color;
        width: 43rem;
        @media (max-width: 700px) {
          font-size: 5rem;
          width: 90%;
        }
        a {
          color: $white-color;
          display: inline-block;
          cursor: pointer;
        }
      }
      .privacy-clr {
        @include flex-center(row);
        width: 9rem;
        height: 9rem;

        border-radius: 50%;
        transition: background 0.5s ease-out;
        cursor: pointer;
        margin-right: 1.5rem;
        @media (max-width: 700px) {
          width: 13rem;
          height: 13rem;
        }
        svg {
          width: 5rem;
          aspect-ratio: 1;
        }
        &:hover {
          background-color: $yalloo-primary-500;
        }
      }
    }
  }
}
