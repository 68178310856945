.loading-page {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999999999 !important;
  @include flex-center(column);
  padding-bottom: 4%;
  background-color: $black-color;

  &__text-container {
    position: relative;
    .spacer {
      background-color: black;
      position: absolute;
      width: 0%;
      height: 100%;
      z-index: 11111;
      right: 0;
    }

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include flex-center(row);
    justify-content: stretch;
    @include over-flow;
    z-index: 1000;
    @media (max-width: 700px) {
      top: 40%;
    }
  }
  &__title--1 {
    color: $yalloo-primary-500;
    font-family: $marco-medium;
    font-weight: 500;
    font-size: 14rem;
    @media (max-width: 700px) {
      font-size: 20rem;
    }
  }
  &__title--2 {
    color: $yalloo-primary-500;
    font-family: $marco-medium;
    font-weight: 500;
    font-size: 14rem;
    @media (max-width: 700px) {
      font-size: 20rem;
    }
  }

  &__text {
    color: $white-color;
    margin-bottom: 1rem;
    z-index: 100;
    margin-top: auto;
    font-size: 2.5rem;
    font-family: $marco-light;
    font-weight: 300;
    @media (max-width: 700px) {
      font-size: 5.5rem;
      margin-top: 100%;
    }
  }
}
