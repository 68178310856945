.input-container {
  padding: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  height: 15rem;
  width: 100%;
  @include flex-center(column);
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  @media (max-width: 700px) {
    height: 35rem;
  }

  .center-input {
    position: relative;
    z-index: 1111;
    @media (max-width: 700px) {
      @include flex-center(row);
      justify-content: space-between;
      margin-top: 4rem;
    }
    width: 100%;

    p {
      font-family: $text-regular;
      font-size: 2.4rem;
      position: absolute;
      top: 5rem;
      right: 0;

      flex: 0.2;
      text-align: right;
      color: $white-color;
      @media (max-width: 700px) {
        font-size: 5rem;
        text-align: left;
        white-space: nowrap;
        position: relative;
        top: auto;
        left: auto;
      }
    }
  }

  .input-wrapper {
    @include flex-center(row);
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    P::first-letter {
      text-transform: capitalize;
    }
  }

  background-color: $purpal-secondary-500;
  .input-label {
    display: block;
    font-size: $size-2-5;
    color: $white-color;
    font-family: $text-regular;
    font-weight: 400;
    @media (max-width: 700px) {
      font-size: 5.5rem;
    }
  }
  textarea {
    width: 100%;
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
    overflow: hidden; /* Prevents the scroll bar from appearing */
    resize: none;
    margin-top: 1.5rem;
    @media (max-width: 700px) {
      margin-top: 4.5rem;
    }
  }
  .input,
  textarea {
    border: none;
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: $purpal-secondary-500;
    font-size: 4rem;
    font-family: $text-medium;
    font-weight: 500;
    color: $white-color;
    align-self: center;
    padding: 0; /* Ensure no padding interference */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    @media (max-width: 700px) {
      font-size: 8rem;
    }
    @include flex-center(row);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $white-color;

      white-space: wrap;
    }
  }
}
.input-container:last-child {
  height: 100%;
}
