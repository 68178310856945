.thank-you {
  @include flex-center(row);
  background-color: $black-color;
  height: 100vh;
  padding: 0rem 12rem 0rem 12rem;
  @media (max-width: 700px) {
    @include flex-center(column);
    padding: 32.8rem 7rem 22rem 7rem;
    gap: 22rem;
    height: auto;
  }

  .left-container {
    @include flex-center(column);
    gap: 5rem;
    @media (max-width: 700px) {
      gap: 11rem;
    }
    height: 100%;
    flex: 1;
    .title {
      font-size: $size-16;
      font-family: $marco-medium;
      font-weight: 500;
      color: $yalloo-primary-500;
      line-height: 0.8;
      align-self: flex-start;
      @media (max-width: 700px) {
        font-size: 20rem;
      }
    }
    .paragraph {
      font-size: 2.4rem;
      font-family: $text-regular;
      font-weight: 400;
      width: 60.6rem;
      line-height: 1.2;
      align-self: flex-start;
      color: $white-color;
      @media (max-width: 700px) {
        font-size: 6rem;
        width: 100%;
      }
    }
    .btn-text-container {
      @include flex-center(row);
      justify-content: flex-start;
      align-self: flex-start;

      span {
        color: $white-color;
        font-family: $text-medium;
        font-weight: 500;
        font-size: $size-4;

        display: inline-block;
        @media (max-width: 700px) {
          font-size: 7rem;
        }
      }
      .crl-btn {
        @media (max-width: 700px) {
          scale: 1.4;
          margin-right: 5rem;
          width: 9rem !important;
          height: 9rem !important;
        }

        svg {
          transform: rotate(180deg);
          width: 4rem !important;
        }
      }
    }
  }

  .right-container {
    height: 100%;
    width: 100%;
    flex: 1;
    @include flex-center(column);
    align-items: flex-end;
    @media (max-width: 700px) {
      @include flex-center(column);
    }

    img {
      width: 60%;
      aspect-ratio: 1;
      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
}
