.not-found {
  background-color: $purpal-secondary-500;
  @include flex-center(column);
  color: $yalloo-primary-500;
  height: 100vh;
  padding-left: 7rem;
  padding-right: 7rem;
  .status-code {
    font-size: 65rem;
    height: min-content;
    width: min-content;
    font-family: $marco-medium;
    font-weight: 500;
    height: 63rem;
    @media (max-width: 700px) {
      font-size: 85rem;
      height: auto;
    }
  }
  .node-sticky {
    width: 100%;
    @include flex-center(column);
    position: relative;
    .note {
      .note__text {
        font-size: 5.5rem !important;
      }
    }
    .note:nth-child(2) {
      top: 20%;
      left: 25%;
      rotate: -21.36deg;
    }
    .note:nth-child(3) {
      top: 66%;
      left: 25%;
      rotate: 10.36deg;
    }
    .note:nth-child(4) {
      top: 30%;
      left: 50%;
      rotate: -10.36deg;
    }
  }

  .g {
    display: inline-block;
    font-size: $size-6;
    font-family: $text-medium;
    font-weight: 500;
    margin-bottom: 6.4rem;
    text-align: center;
    @media (max-width: 700px) {
      font-size: 8rem;
    }
  }
  a {
    position: relative;
    @include text-decoration-none;
    color: $white-color;
    font-size: $size-4;
    font-family: $text-medium;
    font-weight: 500;
    transform: translateY(0rem);
    @media (max-width: 700px) {
      font-size: 8rem;
    }
    &:hover {
      #path {
        @include lineAnimate;
      }
    }
    .home-link-svg {
      position: absolute;
      top: 0;
      width: 100%;
      transform: scaleY(1.5);

      svg {
        width: 100%;
        z-index: 111111111;
      }
    }
  }
}
