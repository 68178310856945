.slide-in {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: $yalloo-primary-500;
  z-index: 9999;

  img {
    width: 65rem;
    font-size: 14rem;
    font-family: $marco-medium;
    font-weight: 500;
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.slide-out {
  @include slide(top);

  img {
    width: 65rem;
    font-size: 14rem;
    font-family: $marco-medium;
    font-weight: 500;
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
