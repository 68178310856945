@mixin flex-center($direction) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center;
}

@mixin over-flow {
  overflow: hidden;
}

@mixin bigFontStyle($color) {
  font-size: $size-19;
  @if ($color) {
    color: $color;
  }
}

@mixin letterStyle {
  font-family: $marco-medium;
  letter-spacing: $letter-spacing-1px;
}

@mixin cursorPointer {
  cursor: pointer;
}

@mixin contactTextStyle {
  color: $white-color;
  font-size: $size-4;
}

@mixin inner-container {
  @include flex-center(row);
  justify-content: flex-end;
  flex-direction: row-reverse;
}

@mixin line-height-28px {
  line-height: 2.88rem;
}

@mixin text-decoration-none {
  text-decoration: none;
}
@mixin list-style-none {
  list-style: none;
}

@mixin footer-policy-style {
  color: $black-color;
  font-size: $size-2-5;
  font-family: $marco-light;
  @include line-height-28px;
}

@mixin paddingFix($direction, $num: 1rem) {
  padding-#{$direction}: $num;
}

@mixin menuPolicyStyle {
  font-size: 2.5rem;
  color: $yalloo-primary-500;
  font-family: $text-regular;
  // line-height: 1;

  display: block;
  width: max-content;
  @include cursorPointer;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    @media (max-width: 700px) {
    }
    background-color: $yalloo-primary-500;
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    transform-origin: left;
  }

  &:hover {
    &::after {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      transform: scaleY(0.8);
    }
  }
}
@mixin stickyPostion {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}

@mixin lineAnimate {
  opacity: 1;
  stroke-dasharray: 1679.4949951171875;
  @include drawPath;
}

@mixin slide($direction) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $yalloo-primary-500;
  transform-origin: $direction;
  z-index: 9999;
}

// aimation keyframe css
@mixin infiniteRotate($duration: 5s) {
  @keyframes infiniteRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: infiniteRotate $duration linear infinite;
}

@mixin UnderLineAinamtion($duration: 0.3s) {
  @keyframes UnderLineAinamtion {
    0% {
      width: 0%;
      opacity: 0;
    }
    100% {
      width: 100%;
      opacity: 1;
    }
  }
  animation: UnderLineAinamtion $duration linear;
}

@mixin scrollRight($duration) {
  @keyframes scrollRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 1rem));
    }
  }
  animation: scrollRight $duration linear infinite;
}

@mixin drawPath {
  @keyframes drawPath {
    0% {
      stroke-dashoffset: 1679.4949951171875;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  animation: drawPath 0.5s linear;
}

// style

//large
@mixin largeMacro {
  font-family: $marco-medium, sans-serif;
  font-size: 30rem;
  line-height: 0.8;
}
@mixin largeH1 {
  font-family: $marco-medium, sans-serif;
  font-size: 16rem;
  line-height: 0.75;
}
@mixin largeH2 {
  font-family: $marco-medium, sans-serif;
  font-size: 9.6rem;
  line-height: 0.8;
}
@mixin largeH3 {
  font-family: $marco-medium, sans-serif;
  font-size: 6rem;
  line-height: 0.9;
}
@mixin largeH4 {
  font-family: $text-medium, sans-serif;
  font-size: 4rem;
  line-height: 0.9;
}
@mixin largeParagraph {
  font-family: $text-regular, sans-serif;
  font-size: 2.4rem;
  line-height: 1.2;
}

//small
@mixin smallH1 {
  font-family: $marco-medium, sans-serif;
  font-size: 5.6rem;
  line-height: 0.8;
}
@mixin smallH2 {
  font-family: $marco-medium, sans-serif;
  font-size: 4.8rem;
  line-height: 0.8;
}
@mixin smallH3 {
  font-family: $marco-medium, sans-serif;
  font-size: 3.2rem;
  line-height: 0.9;
}
@mixin smallH4 {
  font-family: $text-medium, sans-serif;
  font-size: 2.4rem;
  line-height: 0.9;
}
@mixin smallParagraph {
  font-family: $text-regular, sans-serif;
  font-size: 1.6rem;
  line-height: 1.2;
}
