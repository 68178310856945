.below {
  margin-left: auto;
}
.big {
  color: $purpal-secondary-500;
  padding: 0 !important;
}

.black {
  color: black; /* or any other desired color */
  --my-variable: black; /* or any other desired variable */
  fill: black;
}
.white {
  color: white; /* or any other desired color */
  --my-variable: white; /* or any other desired variable */
  fill: white;
}

.full {
  @media (max-width: 700px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.blog_category-container .swiper-slide {
  width: auto !important; /* Ensure the width of the slide is determined by its content */
  flex-shrink: 0; /* Prevent the slide from shrinking */
}

strong {
  font-weight: bold !important;
}
.blog-btn {
  transform: translateY(-30%);
  padding: 0 !important;
}

.s {
  transform: scaleY(1) !important;
}
.l {
  transform: scaleY(0.76) !important;
}

.tl {
  margin-left: 1.2rem !important;
}

.ts {
  @media (max-width: 700px) {
    margin-left: 1 !important;
  }
}

.no-blog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  font-family: $text-medium;
}

.veild {
  background-color: darken($yalloo-primary-500, 30px);
}

.light {
  background-color: $yalloo-primary-500;
}
