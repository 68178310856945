.project-detail {
  width: 100%;
  padding: 18rem 0rem 9.6rem 0rem;
  color: $white-color;
  background-color: $black-color;
  @media (max-width: 700px) {
    padding: 28rem 0rem 9.6rem 0rem;
  }
  .detail-title__container {
    font-family: $marco-medium;
    letter-spacing: 4px;
    font-weight: 500;
    font-size: 16rem;
    color: $yalloo-primary-500;
    margin-bottom: 6rem;
    overflow-y: hidden;
    padding-left: 10rem;
    @media (max-width: 700px) {
      padding-left: 7rem;
      font-size: 18rem;
    }

    h2 {
      line-height: 0.8;
      width: 100%;
      padding: 0 0 2rem 1rem;
    }
  }
  .detail-subtitle__container {
    padding-right: 10rem;
    overflow-y: hidden;
    margin-bottom: 6rem;
    padding-left: 10rem;
    @media (max-width: 700px) {
      margin-bottom: 8rem;
      padding-left: 7rem;
    }

    span {
      font-family: $text-regular;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 1.2;
      display: inline-block;
      @media (max-width: 700px) {
        font-size: 5.5rem;
        margin-bottom: 2rem;
      }
    }
    h3 {
      font-family: $milgram-medium;
      font-weight: 500;
      font-size: $size-6;
      padding-bottom: 1.5rem;

      line-height: 0.9;
      @media (max-width: 700px) {
        font-size: 12rem;
      }
    }
  }
  .detail-minititle__container {
    overflow-y: hidden;

    margin-bottom: 6rem;
    padding-left: 10rem;
    @media (max-width: 700px) {
      padding-left: 7rem;
    }

    span {
      font-family: $text-regular;
      font-weight: 400;
      font-size: 2.4rem;
      display: inline-block;
      @media (max-width: 700px) {
        font-size: 5.5rem;
        margin-bottom: 2rem;
      }
    }
    h3 {
      font-family: $text-medium;
      font-weight: 500;
      font-size: 4rem;
      line-height: 1;
      @media (max-width: 700px) {
        font-size: 8rem;
      }
    }
  }
  .detail-paragraph__container {
    position: relative;
    overflow-y: hidden;
    font-family: $text-regular;
    font-weight: 400;
    font-size: 2.4rem;
    padding-left: 10rem;
    padding-right: 10rem;
    line-height: 1.2;
    margin-bottom: 8rem;
    @media (max-width: 700px) {
      margin-bottom: 12rem;
      font-size: 5.33rem;
      padding-left: 7rem;
    }

    @media (max-width: 700px) {
    }
  }
  .detail-img__container:first-child {
    display: none;
  }
  .detail-img__container {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;

    @media (max-width: 700px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 12rem;
      gap: 12rem;
    }
    padding-left: 10rem;
    padding-right: 10rem;
    margin-bottom: 8rem;
    gap: 8rem;

    @include flex-center(column);
    .mark-down__pd-left {
      margin-right: auto;
      @media (max-width: 700px) {
        padding-left: 7rem !important;
        padding-right: 10rem !important;
        margin-left: unset;
      }
    }
    .mark-down__pd-center {
      margin: auto;
      @media (max-width: 700px) {
        padding-left: 7rem !important;
        padding-right: 10rem !important;
        margin-left: unset;
      }
    }
    .mark-down__pd-right {
      margin-left: auto;

      @media (max-width: 700px) {
        padding-left: 7rem !important;
        padding-right: 10rem !important;
        margin-left: unset;
      }
    }
    .mark-down__pd-left,
    .mark-down__pd-center,
    .mark-down__pd-right {
      p {
        a {
          color: $yalloo-primary-500;
          text-decoration: none;
        }
      }
      width: 83.8rem;
      @media (max-width: 700px) {
        width: auto;
      }
      blockquote {
        p {
          font-family: $marco-medium;
          font-size: 6rem;
          font-weight: 500;
          line-height: 0.9;
          letter-spacing: 0.5rem;
          margin-bottom: 1rem;
          @media (max-width: 700px) {
            letter-spacing: 1.2rem;
            font-size: 11rem;
          }
        }
      }
      h1,
      h2,
      h3,
      h4,
      h6 {
        font-family: $text-medium;
        font-size: 4rem;

        @media (max-width: 700px) {
          font-size: 8rem;
          line-height: 1;
        }
      }
      h5 {
        font-size: 3rem;
        font-family: $text-medium;
        margin-bottom: 2.5rem;
        @media (max-width: 700px) {
          font-size: 5rem;
          font-weight: bold;
        }
      }
      strong {
        font-family: $text-medium;
        font-size: 2.4rem;
        line-height: 0.9;
        display: inline-block;
        font-weight: bold;
        @media (max-width: 700px) {
          font-size: 5.33rem;

          display: inline-block;
        }
      }

      p {
        font-size: 2.4rem;
        font-family: $text-regular;
        line-height: 1.2;
        @media (max-width: 700px) {
          font-size: 5.33rem;
        }
      }
    }

    .detail-img__row {
      position: relative;
      overflow: hidden;

      @include flex-center(row);
      justify-content: stretch;
      align-items: flex-start;
      gap: 4rem;
      width: 100%;
      @media (max-width: 700px) {
        @include flex-center(column);
        padding: 0 7rem;
        gap: 12rem;
      }

      img {
        width: calc(50% - 2rem);
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 700px) {
        }
      }
      video {
        width: 100%;
        align-self: flex-start;
      }
    }
    .block-hidden {
      overflow: hidden;
      flex: 1;
      .center {
        @include flex-center(column);
        align-items: stretch;
        img,
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
