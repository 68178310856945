.approccia-main {
  @include flex-center(row);
  z-index: 1;
  width: 100%;
  background-color: $yalloo-primary-500;
  gap: 10rem;
  padding: 0rem 12rem 10rem 12rem;

  @media (max-width: 700px) {
    @include flex-center(column);
    padding: 0rem 7rem 16.8rem 7rem;
  }

  .primary-container {
    @include flex-center(row);
    font-size: $size-16;

    @media (max-width: 700px) {
      font-size: 20rem;
    }
    font-family: $marco-medium;
    font-weight: 500;

    position: relative;

    align-self: flex-start;
    .slide-sss {
      align-self: center;
      display: inline-block;
      line-height: 0.8;
      padding: 0 1.6rem 1.7rem 1.6rem;

      align-self: center;
    }

    .primary-text {
      color: $black-color;
      height: 60rem;
      .hidden {
        overflow: hidden;
        display: flex !important;
        flex-direction: row;
      }
      .inner-text-wrapper {
        position: relative;

        display: inline-flex;
        .line-container {
          position: absolute;
          right: 0;
          top: 1rem;
          width: 100%;
          transform: scale(1.2, 1.1) !important;

          z-index: 11;
          svg {
            width: 100%;
            height: 100%;
            @media (max-width: 800px) {
              height: auto !important;
            }
          }
        }

        .approccio {
          color: $purpal-secondary-500;
        }
      }

      .btn-wrapper {
        @include flex-center(column);
        margin-left: 2rem;
        margin-top: 2rem;
        cursor: pointer;
      }
    }
  }
  .secondary-text-container {
    position: relative;
    .spacerApp {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      background-color: $yalloo-primary-500;
      transform-origin: bottom;
    }
    overflow: hidden;
    flex: 1;
    align-self: flex-end;
    margin-bottom: 10.4rem;

    @media (max-width: 700px) {
      margin-right: auto;
    }
  }
  .secondary-text {
    overflow: hidden;
    font-size: 2.4rem;
    font-family: $text-regular;
    font-weight: 400;
    line-height: 1.2;
    text-align: right;
    margin-left: auto;

    @media (max-width: 700px) {
      font-size: 5.33rem;
      text-align: left;
      align-self: flex-start;
    }
  }
}
