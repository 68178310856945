.message-container {
  position: fixed;
  top: 0;
  right: 0;
  background-color: $Tertiary-500;
  z-index: 99999;

  span {
    display: inline-block;
    padding: 2rem 4rem;
    font-size: $size-4;
    font-family: $marco-light;
    text-transform: uppercase;
  }
}
