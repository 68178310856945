.location {
  @include flex-center(row);
  padding: 20rem 12rem 35rem 12rem;
  @media (max-width: 700px) {
    padding: 20rem 7rem 30rem 7rem;
  }
  @media (max-width: 700px) {
    @include flex-center(column);
    align-items: flex-start;
    gap: 10rem;
    padding-bottom: 84rem;
  }
  .hide {
    overflow: hidden;
    width: 100%;
  }

  align-items: flex-start;
  background-color: $black-color;
  .left-container {
    @include flex-center(column);
    flex: 1;
    align-items: flex-start;
    gap: 5rem;
    color: $white-color;
    font-family: $text-medium;
    font-weight: 500;
    width: 100%;

    .location-title {
      font-size: 7rem;
      line-height: 1;
      font-family: $milgram-medium;
      line-height: 0.9;
      font-weight: 500;
      width: 55rem;
      margin-bottom: 1rem;
      padding-left: 0.5rem;
      @media (max-width: 700px) {
        font-size: 9rem;
        width: 80rem;
      }
    }
    .address {
      font-size: $size-4;
      width: 50rem;
      font-family: $text-medium;
      line-height: 0.9;
      font-weight: 500;

      @media (max-width: 700px) {
        font-size: 7rem;
        width: 80rem;
      }
    }
    .phone-number {
      font-size: $size-4;
      font-family: $text-medium;
      font-weight: 500;
      line-height: 0.9;
      @media (max-width: 700px) {
        font-size: 7rem;
      }
    }
    .color-white {
      margin-top: $size-4;
      font-family: $text-medium;
      font-weight: 500;

      li {
        text-align: left;
      }

      a {
        color: $yalloo-primary-500;

        @media (max-width: 700px) {
          line-height: 1.5;
        }
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          left: 0;
          top: 2.5rem;

          background-color: $yalloo-primary-500;

          -webkit-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s;
        }

        &:hover {
          &::before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            width: 100%;
          }
        }
      }
    }
  }
  .direction-container {
    @include flex-center(row);
    width: 100%;
    justify-content: flex-start;
    overflow: hidden;

    gap: 0.8rem;
    cursor: pointer;
    &:hover {
      .left-arrow {
        transform: scaleX(1.5) !important;
      }
    }
    .search {
      font-size: $size-2;
      text-transform: uppercase;
      @media (max-width: 700px) {
        font-size: 4rem;
      }
      @include text-decoration-none;
      color: $white-color;
    }
    .left-arrows {
      align-self: flex-start;

      transform-origin: left;
      @media (max-width: 400px) {
        width: $size-4;
      }
    }
  }
  .right-container {
    flex: 1;
    position: relative;
    @media (max-width: 700px) {
      width: 100%;
    }
    .location-map-container {
      .spacer {
        position: absolute;
        transform-origin: bottom;
        height: 100%;
        width: 100%;
        background-color: $black-color;
      }
    }
    .location-map {
      width: 100%;
    }
    .visual-location {
      position: absolute;
      left: -40%;
      bottom: -15%;
      width: 70%;
      z-index: 11;
      @media (max-width: 700px) {
        left: 10%;
        bottom: -45%;
        width: 80%;
      }
    }
  }
}

.direction-container {
  display: flex !important;

  justify-content: flex-start !important;
  align-items: center !important;
  font-family: $text-medium;
  font-weight: 500;
  gap: 0.5rem !important;
  @media (max-width: 700px) {
    gap: 2rem !important;
  }
  cursor: pointer;
  &:hover {
    .left-arrow {
      transform: scaleX(1.5) !important;

      transition: transform 0.5s;
    }
  }
  .search {
    font-size: $size-2;
    @media (max-width: 700px) {
      font-size: 6rem !important;
    }
    @include text-decoration-none;
  }
  .left-arrow {
    align-self: flex-start;
    transition: transform 0.5s;
    transform-origin: left;
    width: 3rem !important;
    align-self: center !important;
    @media (max-width: 700px) {
      padding-top: 0.3rem;
      width: 6rem !important;
    }
  }
}
