.main {
  @include flex-center(column);
  background-color: $yalloo-primary-500;
  padding-top: 18rem;
  @media (max-width: 700px) {
    padding-top: 28rem;
  }

  gap: 8rem;
  &__dream-big-container {
    font-size: 35rem;
    white-space: nowrap;
    width: 100%;
    position: relative;
    gap: 40rem;
    overflow: hidden;

    .marquee-container {
      overflow: hidden;
    }

    .dream-big-one {
      display: inline-block;
      padding-left: 10rem;
      position: relative;
      font-family: $marco-medium;
      font-weight: 500;
    }
  }
  &__text-container {
    width: 100%;

    font-size: $size-16;
    font-family: $marco-medium;
    font-weight: 500;
    max-width: 88%;
    padding-bottom: 16rem;
    flex: 1;
    position: relative;
    @media (max-width: 700px) {
      max-width: 100%;
      padding-left: 7rem;
      font-size: 21rem;
    }

    .text {
      word-break: keep-all;
    }

    .hidden {
      overflow: hidden;
      padding-left: 0.6rem;
      display: flex;
      word-break: keep-all;

      .slide-s {
        display: inline-block;
        line-height: 0.75;
        @media (max-width: 700px) {
          line-height: 0.8;
          padding: 1rem 7rem 1rem 0rem;
        }
        padding: 1rem 26rem 1rem 0rem;
        word-break: keep-all;
      }

      &:last-child {
        padding-bottom: 2.2rem;
      }
    }
  }
}
