.header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;

  &__title {
    font-size: $size-6;
    font-family: $text-medium;
    font-weight: 600;
    letter-spacing: -2px;
    align-self: center;
    line-height: 1;
    cursor: pointer;
    @media (max-width: 700px) {
      font-size: 9rem;
    }

    padding: 0rem 0 0 4rem;
    .letter-cel {
      display: inline-block;

      border-radius: 5px;
      svg {
        width: 100%;
        height: 4rem;
      }
    }

    .title-t {
      @include letterStyle;
    }
    .title-k {
      @include letterStyle;
    }
    .title-word {
      transform: translate(-27%);
      display: inline-block;
    }
  }

  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .soical-menu {
      @include flex-center(row);
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 34rem;

      li {
        text-align: right !important;
      }

      @media (max-width: 700px) {
        align-self: center;
        margin-bottom: 2rem;
        margin-top: 10rem;
        justify-content: space-around;
        width: 100%;
      }
      gap: 8rem;

      z-index: 1111;
      .policy-container {
        @include flex-center(column);
        align-items: flex-start;

        gap: 5px;
        p {
          align-self: flex-end;
        }
      }

      .policy {
        @include menuPolicyStyle;
        padding-bottom: 0.4rem !important;
        @media (max-width: 700px) {
          font-size: 3rem;
        }
        @media (max-width: 700px) {
          font-size: 6rem;
          padding-bottom: 0.7rem !important;
        }
      }
      .cookie {
        @include menuPolicyStyle;
        padding-bottom: 0.4rem !important;
        @media (max-width: 700px) {
          font-size: 3rem;
        }
        @media (max-width: 700px) {
          font-size: 6rem;
          padding-bottom: 0.7rem !important;
        }
      }
      .copy-rights {
        font-size: 2.5rem;
        @media (max-width: 700px) {
          font-size: 3rem;
        }
        @media (max-width: 700px) {
          font-size: 6rem;
        }
        color: $yalloo-primary-500;
        font-family: $text-regular;
        line-height: 1.3;
        display: block;
        width: max-content;
      }
    }

    padding: 4rem 4rem 0 0;
    .nav-menu {
      font-size: $size-3;
      position: relative;
      @media (max-width: 700px) {
        margin-top: 3rem;
      }
      padding-top: 1rem;
      font-family: $text-medium;
      font-weight: 500;

      line-height: 1;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        top: 4.1rem;
        @media (max-width: 700px) {
          top: 6.5rem;
        }
        left: 0;
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        transform-origin: left;
      }

      &:hover {
        &::after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          transform: scaleY(1.1);
          background-color: var(--my-variable);
        }
      }

      @include cursorPointer;
      @media (max-width: 700px) {
        font-size: 5.5rem;
      }
    }
    .nav-colorful {
      margin: 0 !important;
    }

    .menu-open-underline {
      align-self: flex-start;
      margin: 0;
      transform: translate(0, -3px);
      background: $black-color;
    }

    .list-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 11;
      height: 100vh;
      z-index: 1111;
      width: 100vw;

      padding: 4rem 12rem 4rem 12rem;
      @media (max-width: 700px) {
        padding: 0rem 0rem 4rem 0rem;
      }
      .menu-scroll {
        overflow-y: scroll;
        height: 100vh;
      }
      .menu-scroll::-webkit-scrollbar {
        display: none;
      }
      .menu-lang {
        font: 500 2.5rem $text-medium;
        z-index: 111;
        position: relative;
        color: $white-color;
        top: 18vh;
        left: 0.6rem;
        cursor: pointer;
        @include flex-center(row);
        justify-content: flex-start;
        gap: 1.5rem;
        @media (max-width: 700px) {
          font-size: 5.5rem;
          top: 30rem;
          gap: 5rem;
          justify-content: center;
        }
        span {
          display: inline-block;
        }
        .active {
          opacity: 0.5;
        }
      }

      .model-menu {
        position: absolute;
        right: 5%;
        top: 35% !important;
        transform: translateY(-50%);

        height: 50rem !important;

        @media (max-width: 700px) {
          right: unset;
          position: relative;
          top: 25% !important;

          width: 100% !important;
          // overflow: auto;
          // width: 100% !important;
          height: 110rem !important;
        }
        canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .menu-close {
      z-index: 99999999999;
      position: absolute;
      right: 2%;
      color: $white-color;
      font-size: $size-3;
      line-height: 1;
      font-family: $text-medium;
      font-weight: 500;
      position: absolute;
      top: 5rem;
      @include cursorPointer;
      @media (max-width: 700px) {
        font-size: 5rem;
        top: 6rem;
        right: 4rem;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        top: 3.3rem;
        @media (max-width: 700px) {
          top: 5.5rem;
        }
        left: 0;
        transform: scaleX(0);
        background-color: white;
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        transform-origin: left;
      }

      &:hover {
        &::after {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          transform: scaleY(1.1);
        }
      }

      .nav-close-underline {
        align-self: flex-start;
        margin: 0;
        transform: translate(0, -15px);
        z-index: 700;
        display: inline-block;
        background-color: $white-color;
      }
    }

    .menu__star {
      opacity: 1;
      position: absolute;
      top: 8%;
      right: 3.5%;
      @media (max-width: 700px) {
        top: 5.2%;
      }
      // will-change: transform;
    }
    .nav-list {
      @include flex-center(row);
      margin-top: 5rem;
      @media (max-width: 700px) {
        @include flex-center(column);
        justify-content: space-around;
        margin-top: 40rem;
        height: 80%;
      }
      justify-content: space-between;
      align-items: center;

      height: 90%;
      z-index: 1111;
      .list-item {
        align-self: center;
        list-style: none;
        font-size: $size-16;
        font-family: $marco-medium;
        font-weight: 500;

        @media (max-width: 700px) {
          font-size: 20rem;
        }
        line-height: 0.75;
        .items {
          @include text-decoration-none;

          color: $yalloo-primary-500;
          cursor: pointer;
          font-family: $marco-medium;
          align-self: flex-start;
          position: relative;
          padding-left: 0.6rem;

          @media (max-width: 700px) {
            align-self: center;
          }
          &:nth-child(1):hover {
            #path {
              @include lineAnimate;
              transform: scaleY(0.8);
            }
          }
          &:nth-child(2):hover {
            #path {
              @include lineAnimate;
              transform: scaleY(0.76);
            }
            svg {
              top: 1.5rem;
            }
          }
          &:nth-child(3):hover {
            @media (max-width: 700px) {
            }
            #path {
              @include lineAnimate;
              transform: translateY(-4rem) scaleY(1.5) !important;
            }
          }
          &:nth-child(4):hover {
            #path {
              @include lineAnimate;
              transform: scaleY(0.8);
            }
          }
        }
        .lines {
          position: absolute;
          top: 10%;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
        }
      }
      .lines-container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        @media (max-width: 700px) {
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.color-yallow {
  margin-top: $size-4;
  li {
    margin-bottom: 0.5rem;
    @media (max-width: 700px) {
      font-size: 5rem;
    }
    text-align: left !important;
  }

  a {
    color: $yalloo-primary-500 !important;

    @media (max-width: 700px) {
      // line-height: 1.5 !important;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      top: 2.5rem;
      left: 0;
      background-color: $yalloo-primary-500 !important;
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover {
      &::after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        width: 100%;
      }
    }
  }
}
#path {
  opacity: 0;
}

@keyframes drawPath {
  0% {
    stroke-dashoffset: 1679.4949951171875;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
