.note {
  background-color: $black-color;
  position: absolute;
  @include flex-center(row-reverse);
  font-size: 4rem;
  color: $white-color;
  gap: 1rem;
  border-radius: 5rem;
  font-family: $text-medium;
  font-weight: 500;
  will-change: transform;
  padding: 0.65em;
  line-height: 0.9;
  backface-visibility: hidden;
  @media (max-width: 600px) {
    font-size: 3rem;
    border-radius: 13rem;

  }
  span {
    align-self: center;
    font-family: $text-medium;
    display: inline-block;
  }
  .white-star {
    width: 4rem;
    height: 4rem;

    rotate: -21.36deg;
    align-self: center;
    @include infiniteRotate(2s);
    will-change: transform;
    backface-visibility: hidden;
  }
}
