.card-container {
  padding: 6.4rem;
  width: 100%;
  @media (max-width: 700px) {
    @include flex-center(column-reverse);
    padding: 10rem;
  }

  .card-holder {
    @include flex-center(row);
    @media (max-width: 700px) {
      @include flex-center(column-reverse);
      gap: 5rem;
      align-items: flex-start;
    }
    align-items: stretch;
    .card-column {
      @include flex-center(column);
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;
      gap: $size-2-5;
      @media (max-width: 700px) {
        gap: 4rem;
      }
      .card-topper {
        font-size: 2.4rem;
        font-family: $text-regular;
        line-height: 1.2;
        font-weight: 400;
        color: $black-color;
        @media (max-width: 700px) {
          font-size: 5rem;
        }
      }
    }
    .card-middler {
      font-family: $milgram-medium;
      font-weight: 500;
      font-size: $size-6;
      width: 80%;
      line-height: 0.9;
      @media (max-width: 700px) {
        font-size: 11rem;
        width: 100%;
      }
    }

    .card-bottomer {
      font-size: 2.4rem;
      font-family: $text-regular;
      font-weight: 400;
      color: $black-color;
      width: 80%;
      @media (max-width: 700px) {
        font-size: 5.5rem;
        width: 100%;
      }
    }

    .card-btn {
      font-size: 2rem;
      font-family: $marco-medium;
      font-weight: 500;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      @media (max-width: 700px) {
        font-size: 5.5rem;
        margin-top: 2rem;
      }
    }
    .card-img {
      flex: 0.5;
      overflow: hidden;
      aspect-ratio: 441.281/443.281;
      @media (min-width: 700px) {
        width: 44.1281rem;
        aspect-ratio: 441.281/443.281;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: scale 0.8s ease-out;
        cursor: pointer;
        &:hover {
          scale: 1.1;
        }
      }
    }
  }
}
