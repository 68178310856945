.crl-btn {
  @include flex-center(row);
  background-color: $purpal-secondary-500;
  width: 9rem;
  height: 9rem;
  @media (max-width: 700px) {
    width: 13rem;
    height: 13rem;
  }

  border-radius: 50%;
  margin-right: 2.4rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    width: 13rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: $Tertiary-500;
    position: absolute;
    top: 100%;
    transition: top 0.3s ease-out;
  }
  &:hover::after {
    top: 0;
  }
  &:hover > img {
    filter: invert(71%) sepia(86%) saturate(5764%) hue-rotate(164deg)
      brightness(99%) contrast(101%);
    @media (max-width: 700px) {
      width: 6rem;
    }
    width: 4rem;
  }

  svg {
    width: 3.5rem;
    @media (max-width: 700px) {
      width: 5rem;
    }
    z-index: 11;
    transition: width 0.3s ease-out;
  }
}
