.main-project {
  padding: 18rem 12rem 0rem 12rem;
  background-color: $black-color;
  @include flex-center(column);
  justify-content: flex-start;
  @media (max-width: 700px) {
    padding: 28rem 7rem 6.4rem 7rem;
  }
  .project__title-container {
    overflow-y: hidden;
    margin-bottom: 4rem;
    align-self: flex-start;
    padding-right: 1rem;
  }
  h3 {
    font-size: $size-16;
    color: $yalloo-primary-500;
    font-family: $marco-medium;
    font-weight: 500;
    transform: translateY(140%);
    @media (max-width: 700px) {
      font-size: 20rem;
      width: 90rem;
      text-align: left;
      margin-bottom: 10rem;
    }
  }
  .spacer-p {
    height: 102%;
    width: 101%;
    transform: scaleY(1);
    position: absolute;
    background-color: $black-color;
    z-index: 11;
    bottom: 0;
  }
  .project-collection {
    @include flex-center(row);
    @media (max-width: 700px) {
      @include flex-center(column);
    }
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    align-self: flex-start;

    width: 100%;
    height: 100%;
  }
  .project__section-container {
    width: calc(50% - 4rem);
    @media (max-width: 700px) {
      width: 100%;
    }
    margin-bottom: 12rem;

    .project__img-container {
      width: 100%;
      position: relative;
      aspect-ratio: 1;
      margin-bottom: 3rem;
      overflow: hidden;
      transition: scale 2s linear !important;
      cursor: pointer;
      clip-path: inset(0% 0% 100% 0%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: inline-block;
      }
    }
    .project__text-container {
      height: 0;
      color: $white-color;
      font-size: 2.5rem;
      @media (max-width: 700px) {
        font-size: 6rem;
      }
      h4 {
        font-family: $text-medium;
        font-weight: normal;
        margin-bottom: 0.5rem;
      }
      p {
        font-family: $text-regular;
        font-weight: 400;
        @media (max-width: 700px) {
          font-size: 5.5rem;
          line-height: 1.1;
        }
      }
    }
  }
}
