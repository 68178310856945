html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
* {
  zoom: 1;
  -webkit-text-size-adjust: none;
}

html::-webkit-scrollbar {
  display: none;
}

:root {
  touch-action: pan-y pinch-zoom;
  height: 100%;
}

html {
  font-size: 0.52085vw;

  @media (max-width: 700px) {
    font-size: 0.77vw;
  }

  -webkit-font-smoothing: antialiased;

  scrollbar-width: none; /* Firefox */

  --webkit-transform: translate3d(0, 0, 0);
}

body {
  @media (max-width: 700px) {
    background-color: black;
  }
}
.contents {
  overflow-x: clip;
  background-color: $yalloo-primary-500;
}
html.lenis {
  height: auto;
}
.lenis.lenis-smooth {
  scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

p {
  cursor: default;
}

video {
  will-change: transform;
}
