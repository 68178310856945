.blog-detail {
  background-color: $black-color;
  padding: 18rem 12rem 10.8rem 12rem;
  @include flex-center(column);
  @media (max-width: 700px) {
    padding: 25rem 0rem 20rem 0rem;
  }
  align-items: flex-start;
  gap: 4rem;
  .card-topper {
    font-size: 2.4rem;
    font-family: $text-regular;
    font-weight: 400;
    color: $black-color;
    gap: 3.5rem;
    @media (max-width: 700px) {
      padding: 0rem 7rem 0rem 7rem;
      gap: 6rem;
    }
    @include flex-center(row);
    align-items: flex-start;
    .blog_date {
      font-size: 2.4rem;
      font-family: $text-regular;
      font-weight: 400;
      color: $white-color;
      @media (max-width: 700px) {
        font-size: 5rem;
      }
    }
    @media (max-width: 700px) {
      font-size: 5rem;
    }
  }

  .blog-detail__title-wrapper {
    overflow-y: hidden;
    padding-left: 0.5rem;
    margin-bottom: 4rem;
    @media (max-width: 700px) {
      padding: 0rem 7rem 0rem 7rem;
    }
    .hidden {
      overflow: hidden;
      padding: 0 0.5rem 2.5rem 0.8rem;
    }
    .hidden:nth-child(1) {
      height: 18rem;
      @media (max-width: 700px) {
        height: auto;
      }
    }
    .hidden:nth-child(2) {
      transform: translateY(-4rem);
    }
    .hidden:nth-child(3) {
      transform: translateY(-4rem);
    }
    .hidden:nth-child(4) {
      transform: translateY(-4rem);
    }
    .hidden:nth-child(5) {
      transform: translateY(-4rem);
    }
    .hidden:nth-child(6) {
      transform: translateY(-4rem);
    }
    .slide-blog-detail {
      font-size: $size-16;
      font-family: $marco-medium;
      font-weight: 500;
      color: $yalloo-primary-500;
      line-height: 0.78;
      display: inline-block;
      transform: translateY(110%);
      @media (max-width: 700px) {
        font-size: 18rem;
        margin-bottom: 2rem;
      }
      span {
        display: block;
      }
    }
  }
  .card-im {
    width: 100%;
    overflow: hidden;
    margin-bottom: calc(8rem - 4rem);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .markdowns-wrapper {
    @include flex-center(column);
    gap: 8rem;
    padding-right: calc(12rem + 8rem + 4rem);
    @media (max-width: 700px) {
      padding: 0rem 7rem 0rem 7rem;
    }
  }
  .blog__markdown-one-wrapper {
    @include flex-center(row);
    @media (max-width: 700px) {
      @include flex-center(column);
    }
    .blog_category-container {
      @include flex-center(column);
      width: 25% !important;
      @media (max-width: 700px) {
        @include flex-center(row);
        justify-content: flex-start;
        gap: 3rem;
        width: 100% !important;
        margin-bottom: 10rem;
        margin-top: 4rem;
      }

      justify-content: flex-start;
      align-self: flex-start;
      gap: 2rem;
      .share-s-color {
        background-color: $Tertiary-500 !important;
      }
      .blog_category-item-a {
        color: $Tertiary-500;
        background-color: $purpal-secondary-500;

        font-size: 2.4rem;
        font-family: $text-medium;
        padding: 2.4rem;
        border-radius: 50px;
        position: relative;
        cursor: pointer;
        @include flex-center(column);
        align-self: flex-start;
        span {
          display: inline-block;
          height: 2.4rem;
          @media (max-width: 700px) {
            height: auto;
          }
        }
        @media (max-width: 700px) {
          font-size: 5rem;
          padding: 4rem;
        }
        ul {
          @include flex-center(row);

          gap: 2rem;

          li {
            list-style: none;
            align-self: center;
            display: inline-block;
            margin: auto;

            a {
              text-decoration: none;
              @include flex-center(column);
              svg {
                width: 2.4rem;
                height: 2.4rem;
                @media (max-width: 700px) {
                  width: 5.5rem;
                  height: 5.5rem;
                }
              }
            }
          }
        }
      }
      .blog_category-item-b {
        color: $Tertiary-500;
        background-color: $purpal-secondary-500;

        font-size: 2.4rem;
        font-family: $text-medium;
        padding: 2.4rem;
        border-radius: 50px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        @include flex-center(column);
        align-self: flex-start;
        @media (max-width: 700px) {
          font-size: 5rem;
          padding: 4rem;
        }

        &::after {
          content: "";
          width: 100%;
          aspect-ratio: 1/1;
          border-radius: 5px;
          background-color: $Tertiary-500;
          position: absolute;
          top: 100%;
          transition: top 0.3s ease-out;
        }
        &:hover::after {
          top: 0;
        }
        &:hover > span {
          color: $purpal-secondary-500;
          z-index: 111;
        }
      }
    }

    .blog__markdown-one {
      font-family: $text-medium;
      width: 100%;
      line-height: 1;
      color: white;
      font-size: 2.4rem;
      display: block;
      @media (max-width: 700px) {
        font-size: 5.33rem;
      }
      blockquote {
        p {
          font-family: $marco-medium;
          font-size: 6rem;
          font-weight: 500;
          line-height: 0.9;
          letter-spacing: 0.5rem;
          margin-bottom: 0rem;
          @media (max-width: 700px) {
            letter-spacing: 1.2rem;
            font-size: 11rem;
          }
        }
      }
      video {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 5rem;
      }
      video::-webkit-media-controls-start-playback-button {
        display: none;
      }
      video::-webkit-media-controls {
        display: none !important;
      }
      p {
        a {
          color: $yalloo-primary-500;
          text-decoration: none;
        }
      }
      strong {
        font-family: $text-medium;
        font-size: 2.4rem;
        line-height: 0.9;
        font-weight: bold;
        display: inline-block;
        @media (max-width: 700px) {
          font-size: 5.33rem;

          display: inline-block;
        }
      }
      ol,
      ul {
        margin-left: 2.8rem;
        @media (max-width: 700px) {
          margin-left: 4.4rem;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h6 {
        font-family: $text-medium;
        font-size: 4rem;
        margin-bottom: 2.5rem;
        @media (max-width: 700px) {
          font-size: 8rem;
          line-height: 1;
        }
      }
      p,
      li {
        margin: 0 0 6.4rem 0;
        font-family: $text-regular;
        @media (max-width: 700px) {
          margin: 0 0 10rem 0;
        }
        line-height: 1.2;
      }

      h5 {
        font-size: 3rem;
        font-family: $text-medium;
        margin-bottom: 2.5rem;
        @media (max-width: 700px) {
          font-size: 5rem;
          font-weight: bold;
        }
      }
      img {
        width: 100%;
      }
    }
  }
  .blog__markdown-two-wrapper {
    @include flex-center(column);
    padding-left: 23rem;
    .blog__markdown-two {
      font-family: $text-regular;
      line-height: 1;
      color: white;
      font-size: 2.4rem;
    }
  }

  .blog__markdown-three-wrapper {
    @include flex-center(column);
    padding-left: 23rem;
    .blog__markdown-three {
      font-family: $text-regular;
      color: white;
      font-size: 8rem;
      line-height: 0.8;
    }
  }
  .blog-img__wrapper {
    @include flex-center(column);
    padding-left: 23rem;
    img {
      width: 100%;
    }
  }
}
