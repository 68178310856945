.portfolio {
  padding: 12rem 12rem 16.8rem 12rem;
  background-color: $black-color;
  position: relative;
  @media (max-width: 700px) {
    padding: 0rem 7rem 64px 7rem;
  }
  .portfolio__first-block {
    @include flex-center(row);
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    @media (max-width: 700px) {
      justify-content: center;
      padding: 0rem 7rem 16.8rem 7rem;
    }
    .portfolio__fkt-wrapper {
      img {
        width: 125rem;
        aspect-ratio: 1.3;
      }
    }
    .portfolio__git-wrapper {
      @include flex-center(column);
      position: absolute;
      gap: 7rem;
      bottom: -3rem;
      right: 0;
      z-index: 11;
      @media (max-width: 700px) {
        right: 50%;
        top: 68rem;
        bottom: unset;
        transform: translateX(50%);

        aspect-ratio: 240/240;
        width: 80.29%;
        object-fit: cover;
      }
      .guy {
        width: 60rem;
        aspect-ratio: 1;
        @media (max-width: 700px) {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .portfolio__second-block {
    align-self: flex-start;
    color: $white-color;
    @include flex-center(row);
    justify-content: space-between;
    @media (max-width: 700px) {
      @include flex-center(column);
      gap: 1rem;
    }

    .portolio__paragraph-wrapper {
      font-family: $text-regular;
      font-weight: 400;
      font-size: 2.4rem;
      width: 70rem;
      @media (max-width: 700px) {
        font-size: 5.5rem;
        width: 100%;
        margin-top: 62rem;
      }
    }
    .portfolio__right-container {
      color: $white-color;
      align-self: flex-start;
      margin-top: 10rem;
      @media (max-width: 700px) {
        @include flex-center(column);
        transform: translateY(0rem);
        margin-right: 0rem;
        width: 100%;
      }
      .slide-wrapper {
        font: 500 4rem $marco-medium;
        width: 62.5rem;
        text-align: left;
        transform: translateX(2rem);
        .hidden {
          overflow: hidden;
        }
        span {
          letter-spacing: 0.4rem;
          display: inline-block;
          padding-left: 1rem;
        }
        margin-bottom: 2.4rem;
        @media (max-width: 700px) {
          font-size: 8rem;
          width: 100%;
          margin-bottom: 5rem;
        }
      }
      .btn-wrapper {
        @include flex-center(row-reverse);
        cursor: pointer;
        justify-content: flex-end;
        padding-left: 2.6rem;
        @media (max-width: 700px) {
          justify-content: stretch;
        }
        width: 100%;
        .btn-text-wrapper {
          font: 500 4rem $text-medium;
          color: "white";
          @media (max-width: 700px) {
            font-size: 8rem;
            width: 100%;
          }
        }
        .btn-container {
          transform: rotate(90deg);
        }
      }
    }
  }
}
.spacer-portfolio {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: $black-color;
  z-index: 11;
  bottom: 0;
}
