.team {
  padding: 12rem 12rem 0rem 12rem;
  background-color: $black-color;
  @include flex-center(column);
  justify-content: flex-start;
  position: relative;
  @media (max-width: 700px) {
    padding: 20rem 7rem 0rem 7rem;
  }
  .team__title-container {
    overflow-y: hidden;
    margin-bottom: 10rem;
    align-self: flex-start;
    position: sticky;
    top: 50%;
    margin-top: 20rem;
    transform: translateY(-50%);
    width: 100%;
    @include flex-center(column);
    @media (max-width: 700px) {
      position: static;
      top: 0;
      margin-top: 0rem;
      transform: translateY(0%);
      margin-bottom: 10rem;
    }
    span {
      position: relative;
      font-size: $size-6;
      z-index: 11;
      font-family: $marco-medium;
      font-weight: 400;
      letter-spacing: 3px;
      color: $yalloo-primary-500;
      @media (max-width: 700px) {
        font-size: 10rem;
      }
    }
  }
  h3 {
    font-size: 30rem;
    color: $yalloo-primary-500;
    font-family: $marco-medium;
    font-weight: 500;
    text-align: center;
    @media (max-width: 700px) {
      font-size: 37rem;
    }
  }
  .spacer-t {
    height: 101%;
    width: 101%;
    position: absolute;
    background-color: $black-color;
    z-index: 11;
    bottom: 0;
  }
  .team-collection {
    @include flex-center(row);
    @media (max-width: 700px) {
      @include flex-center(column);
    }
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    align-self: flex-start;

    width: 100%;
    height: 100%;
  }
  .team__section-container {
    width: calc(40% - 4rem);
    @media (max-width: 700px) {
      width: 100%;
    }
    margin-bottom: 12rem;
    .add-space {
      height: 50rem;
    }

    .team__img-container {
      width: 100%;
      position: relative;
      margin-bottom: 3rem;
      overflow: hidden;
      transition: scale 2s linear !important;
      @media (max-width: 700px) {
        margin-bottom: 5rem;
      }

      img {
        width: 100%;

        aspect-ratio: 1;
        object-fit: cover;
        display: inline-block;
      }
    }
    .team__text-container {
      @include flex-center(row);
      justify-content: space-between;
      align-items: flex-start;
      color: $white-color;
      font-size: 2.5rem;
      @media (max-width: 700px) {
        font-size: 6rem;
      }
      h4 {
        font-family: $text-medium;
        font-weight: normal;
        margin-bottom: 0.5rem;
      }
      p {
        font-family: $text-regular;
        font-weight: 400;
        @media (max-width: 700px) {
          font-size: 5.5rem;
          line-height: 1;
          width: 48rem;
        }
      }
      .team__profile-wrapper {
        @include flex-center(row);
        cursor: pointer;
        gap: 1rem;
        @media (max-width: 700px) {
          gap: 2rem;
        }

        h4 {
          font-family: $text-regular;
          font-weight: normal;
          margin-bottom: 0rem;
          font-size: 2.4rem;
          @include flex-center(row);
          @media (max-width: 700px) {
            font-size: 5rem;
          }
        }
        img {
          vertical-align: middle;
          width: 3rem;
          @media (max-width: 700px) {
            width: 5rem;
          }
          aspect-ratio: 1;
        }
      }
    }
  }
  .team__section-container:nth-child(2) {
    transform: translateY(40rem);
    @media (max-width: 700px) {
      transform: unset;
    }
  }
}
