.stratogy__container {
  width: 100%;
  padding: 20rem 12rem;
  @include flex-center(row);
  z-index: 111;
  position: relative;
  top: 0;
  @media (max-width: 700px) {
    height: 600vh !important;
    padding-left: 7rem;
    padding-right: 7rem;
    padding-bottom: 0;
  }

  .items-container {
    flex: 1;

    @include flex-center(row);
    gap: 19rem;
    @include stickyPostion;
    @media (max-width: 700px) {
      position: sticky !important;
      top: 10%;
      @include flex-center(column);
      align-items: flex-start;
      justify-content: flex-start;
      height: auto !important;
      gap: 13rem;
    }
  }

  background-color: $yalloo-primary-500;
  .word-list {
    list-style: none;
    transform: translateX(10rem);
    @media (max-width: 700px) {
      transform: translate(0) !important;
    }
    li {
      @media (max-width: 700px) {
        transform: translate(0) !important;
      }
    }

    will-change: transform;
    @media (max-width: 700px) {
      position: "relative";
      top: 0;
      margin-top: 0;
    }
    .first-title {
      position: absolute;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      color: $purpal-secondary-500;
      width: 100%;
      height: 100%;
    }
    h3 {
      font-size: $size-16;
      will-change: transform;
      font-family: $marco-medium;
      font-weight: 500;
      line-height: 0.8;
      letter-spacing: 0.6rem;
      font-family: $marco-medium;
      padding: 0 1.6rem 3rem 1.6rem;
      @media (max-width: 700px) {
        font-size: 20rem;
      }
    }
    .second-title {
      overflow: hidden;
      flex-direction: column;
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
  .stratogy-left-side {
    @include flex-center(column);
    align-items: flex-start;
    flex: 1;
    @include stickyPostion;
    top: 40%;
    gap: 3rem;
    @include over-flow;
    @media (max-width: 700px) {
      margin-bottom: 0;
      position: relative;
      top: 0;
      margin-bottom: 0;
      margin-top: 0;
      flex-basis: 50%;
    }
  }
  .stratogy-text {
    font-size: 2.4rem;
    line-height: 1.2;

    width: 60rem;
    font-family: $text-regular;
    font-weight: 400;
    @media (max-width: 700px) {
      font-size: 5.5rem;
      width: 100%;
    }
  }
  .stratogy-text-container {
    @include flex-center(row);
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;

    .stratogy-img {
      @include infiniteRotate(2s);
      width: 2rem;
      margin-top: 0.7rem;
      display: none;
      @media (max-width: 700px) {
        width: 4rem;
      }
    }
    .stratogy-short-text {
      color: $purpal-secondary-500;
      font-size: $size-4;
      font-family: $text-medium;
      font-weight: 500;

      @media (max-width: 700px) {
        font-size: 7rem;
      }
    }
  }
}
