.blog_page {
  background-color: $black-color;
  padding: 18rem 0rem 10rem 0rem;
  position: relative;

  @media (max-width: 700px) {
    padding: 28rem 0rem 10rem 0rem;
  }
  .blog_category-container {
    @media (max-width: 700px) {
      padding-left: 7rem;
    }
    padding-left: 12rem;
  }
  @include flex-center(column);
  align-items: flex-start;
  gap: 8rem;
  @media (max-width: 700px) {
    gap: 15rem;
  }
  .blog_title-container {
    font-size: $size-16;
    color: $yalloo-primary-500;
    font-family: $marco-medium;
    font-weight: 500;
    letter-spacing: 4px;

    @media (max-width: 700px) {
      padding-left: 7rem;
      padding-right: 7rem;
    }
    padding-left: 12rem;
    padding-right: 12rem;

    overflow: hidden;
    h3 {
      transform: translateY(100%);
    }
    @media (max-width: 700px) {
      font-size: 20rem;
    }
  }
  .blog_category-container {
    @include flex-center(row);
    justify-content: flex-start;
    overflow: hidden;
    transform: translateY(150%);

    gap: 2rem;
    width: 100rem;
    flex-wrap: wrap;

    @media (max-width: 700px) {
      flex-wrap: nowrap;
      width: 100%;
    }
    .blog_category-item.active::after {
      top: 0;
    }
    .active > span {
      color: $purpal-secondary-500;
      z-index: 111;
    }
    .blog_category-item {
      color: $Tertiary-500;
      background-color: $purpal-secondary-500;
      font-size: 2.4rem;
      font-family: $text-medium;
      font-weight: 500;
      line-height: 1.2;
      padding: 2.4rem;
      border-radius: 4rem;
      text-align: center;

      @media (max-width: 700px) {
        border-radius: 40px;
      }
      @include flex-center(column);
      align-self: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &::after {
        content: "";
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 4rem;
        background-color: $Tertiary-500;
        position: absolute;
        top: 100%;
        transition: top 0.3s ease-out;
        @media (max-width: 700px) {
          border-radius: 5px;
        }
      }
      &:hover::after {
        top: 0;
      }

      &:hover > span {
        color: $purpal-secondary-500;
        z-index: 111;
      }
      @media (max-width: 700px) {
        font-size: 5rem;
        padding: 4rem;
      }
    }
  }
  .blog-per-item {
    position: relative;
    @media (max-width: 700px) {
      padding-left: 7rem;
      padding-right: 7rem;
    }
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .spacer-blog {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: $black-color;
    z-index: 11;
    transform: scaleY(1);
  }
  .card-holder {
    padding-left: 1rem;
    .card-column {
      justify-content: center;
      gap: 4rem;
      @media (max-width: 700px) {
        gap: 5rem;
      }
    }
    .card-middler {
      @media (max-width: 700px) {
        margin-bottom: 2rem;
      }
    }

    .card-img {
      @media (max-width: 700px) {
        margin-bottom: 8rem;
      }
      img {
        object-position: right;
        object-fit: cover !important;
        @media (max-width: 700px) {
          aspect-ratio: unset !important;
        }
      }
    }
  }
}
