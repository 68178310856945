.section__container {
  background-color: $purpal-secondary-500;
  @include flex-center(row);
  width: 100vw;
  height: 450vh;
  justify-content: flex-start;
  position: relative;
  z-index: 999;
  will-change: transform;
  .section-sticky {
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    @include stickyPostion;
    top: -5vh;
    will-change: transform;
    @media (max-width: 700px) {
      top: 10vh;
    }
    @media (max-height: 1000px) and (min-width: 1200px) {
      top: -5%;
    }

    @media (max-height: 1390px) and (max-width: 1200px) {
      top: 30%;
    }
    @media (max-height: 1000px) and (max-width: 1200px) {
      top: 10%;
    }
  }

  &-paragraph {
    will-change: transform;
    font-size: 100em;
    text-indent: -4px;
    font-family: $marco-medium;
    font-weight: 500;
    @media (max-width: 700px) {
      font-size: 200em;
    }
    color: $yalloo-primary-500;
    position: relative;
  }

  .note {
    font-size: 4em;
    @media (max-width: 700px) {
      font-size: 10em;
    }
    .white-star {
      width: 0.7em;
      height: 0.7em;
      @media (max-width: 700px) {
      }
    }
  }
  .note:nth-child(1) {
    top: 20%;
    left: 3%;
    rotate: -21.36deg;
    @media (max-width: 600px) {
      top: 32%;
    }
  }
  .note:nth-child(2) {
    bottom: 22%;
    left: 15%;
    rotate: 21.36deg;
    @media (max-width: 600px) {
      bottom: 32%;
    }
  }
  .note:nth-child(3) {
    top: 32%;
    left: 30%;
    rotate: -21.36deg;
  }
  .note:nth-child(4) {
    bottom: 32%;
    left: 40%;
    rotate: 21.36deg;
  }
  .note:nth-child(5) {
    top: 32%;
    left: 60%;
    rotate: -21.36deg;
  }
  .note:nth-child(6) {
    bottom: 32%;
    left: 80%;
    rotate: -21.36deg;
  }
  .note:nth-child(7) {
    top: 34%;
    left: 90%;
    rotate: 21.36deg;
    @media (max-width: 600px) {
      top: 40%;
    }
  }
}
