.policy-privacy {
  width: 100%;
  background-color: $black-color;
  padding: 16.8rem 12rem 2rem 12rem;
  color: $white-color;

  @media (max-width: 700px) {
    padding-left: 7rem;
    padding-right: 7rem;
    padding: 32.8rem 12rem 12rem 12rem;
  }

  @include flex-center(column);

  .markdown-policy-privacy {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $text-medium;
      font-size: 4rem;
      line-height: 1;
      margin-bottom: 2.51rem;
      display: inline-block;
      @media (max-width: 700px) {
        font-size: 8rem;

        display: inline-block;
      }
    }
    blockquote {
      P {
        font-family: $text-medium;
        font-size: 6rem;
        line-height: 1.2;
      }
    }
    P {
      strong {
        font-family: $text-medium;
        font-size: 4rem;
        line-height: 0.9;
        display: inline-block;
        @media (max-width: 700px) {
          font-size: 5rem;

          display: inline-block;
        }
      }
    }
    p {
      font-size: 2.4rem;
      font-family: $text-regular;
      margin-bottom: 8rem;
      line-height: 1.2;
      @media (max-width: 700px) {
        font-size: 5rem;
        margin-bottom: 12rem;
      }
    }
  }
}
