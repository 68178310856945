@font-face {
  font-family: "marco-bold";
  src: url("../assets/fonts/milligram/milligram_macrobold_macroman/milligrammacro-bold-webfont.woff")
    format("woff");
}
@font-face {
  font-family: "marco-medium";
  src: url("../assets/fonts/milligram/milligram_macromedium_macroman/milligrammacro-medium-webfont.woff")
    format("woff");
}
@font-face {
  font-family: "marco-light";
  src: url("../assets/fonts/milligram/milligram_light_macroman/milligram-light-webfont.woff")
    format("woff");
}

@font-face {
  font-family: "text-bold";
  src: url("../assets/fonts/milligram/milligram_textbold_macroman/milligramtext-bold-webfont.woff")
    format("woff");
}
@font-face {
  font-family: "text-regular";
  src: url("../assets/fonts/milligram/milligram_textregular_macroman/milligramtext-regular-webfont.woff")
    format("woff");
}

@font-face {
  font-family: "text-medium";
  src: url("../assets/fonts/milligram/milligram_textmedium_macroman/milligramtext-medium-webfont.woff")
    format("woff");
}

@font-face {
  font-family: "milgram-medium";
  src: url("../assets/fonts/milligram/milligram_medium_macroman/milligram-medium-webfont.woff")
    format("woff");
}

// font family

$marco-bold: "marco-bold";
$marco-medium: "marco-medium";
$marco-light: "marco-light";
$milgram-medium: "milgram-medium";

$text-bold: "text-bold";
$text-regular: "text-regular";
$text-medium: "text-medium";

// font size

$size-1: 1rem; // => 10 px
$size-2: 2rem;
$size-2-5: 2.5rem;
$size-3: 3rem;
$size-4: 4rem;
$size-6: 6rem;
$size-16: 16rem;
$size-19: 19rem;
$size-20: 20rem;

// letter-spacing
$letter-spacing-1px: 1px;
$letter-spacing-2px: 2px;
