.cursor {
  position: fixed;
  z-index: 99999999999;
  pointer-events: none;
  overflow: hidden;
  @media (max-width: 1000px) {
    display: none;
  }
  .small-star {
    width: 100%;
    height: 100%;
    @include infiniteRotate(2s);
  }

  .text-content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 1111; // Ensure the content stays on top of the shape
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    img {
      width: 3rem;
      aspect-ratio: 1;
    }

    span {
      color: #5df814;
      font-family: $text-medium;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      font-size: 2.4rem;
    }
  }
}

.pointer {
  z-index: 99999999999;
  width: 16.8rem;
  aspect-ratio: 168/168;
  border-radius: 50%;
  @include flex-center(row);
  gap: 0.8rem;
  background-color: $purpal-secondary-500;
  @media (max-width: 700px) {
    display: none;
  }
}
